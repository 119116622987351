// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import { PAGE } from 'services/Constants';
import { useAppDispatch, useAppSelector } from 'store';
import { useEventBus } from 'EventBus';
import { currentProject } from 'redux/datasets';
import { selectNotification, updateNotificationState, selectPage, checkForUsernames } from '../../redux/actions';
import { getActiveNotification, getUsernames, getPage } from '../../redux/selectors';
import DosApi from '../../services/DosApi';
import formatMentions from '../../services/MentionFormatting';

const NotificationsListItem = ({ notification }) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const navigate = useNavigate();
    const currentPage = useAppSelector(getPage);
    const project = useAppSelector(currentProject);
    const activeNotification = useAppSelector(getActiveNotification);
    const usernames = useAppSelector(getUsernames);
    const sender = usernames ? usernames.find((x) => x.id === notification.sender_id) : null;

    const [error, setError] = useState(null);

    const formatTimestamp = (timestamp) => {
        timestamp = new Date(timestamp);
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const result = `${timestamp.getDate()} ${months[timestamp.getMonth()]} ${timestamp.getFullYear()} –
        ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`;
        return result;
    };

    const active = activeNotification === notification.id;

    const setActive = () => {
        dispatch(selectNotification(active ? null : notification.id));
    };

    const color = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'red';
            case 'system_update':
            case 'user_registered_in_organization':
                return 'green';
            default:
                return 'yellow';
        }
    };

    const icon = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'fa-warning';
            case 'system_update':
            case 'user_registered_in_organization':
                return 'fa-circle-info';
            default:
                return 'fa-message-exclamation';
        }
    };

    const tagline = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'downtime due to maintenance';
            case 'system_update':
                return 'system update information';
            case 'annotation_comment':
                return 'commented on your annotation';
            case 'annotation_comment_reply':
                return 'replied to your comment';
            case 'annotation_comment_mention':
                return 'mentioned you in a comment';
            case 'annotation_comment_reply_mention':
                return 'mentioned you in a reply';
            case 'user_registered_in_organization':
                return 'joined your organization';
            default:
                return '';
        }
    };

    const headline = () => (
        <span className="notification-title">
            <b>{sender ? `${sender.given_name} ${sender.family_name}` : 'Loading User'}</b>
            {' – '}
            {tagline()}
        </span>
    );

    const followCommentLink = async () => {
        try {
            const pathInformation = await DosApi.fetchCommentPath(notification.source_id);
            const slug = pathInformation.project_name
                .toString()
                .normalize('NFD') // break accented characters into components
                .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                .toLowerCase()
                .replace(/\s+/g, '-') // spaces to dashes
                .replace(/&/g, '-and-') // ampersand to and
                .replace(/[^\w-]+/g, '') // remove non-words
                .replace(/--+/g, '-') // collapse multiple dashes
                .replace(/^-+/, '') // trim starting dash
                .replace(/-+$/, ''); // trim ending dash;
            let path = `/project/${pathInformation.project_id}/${slug}`;
            if (pathInformation.parameters) {
                const parameters = [];
                for (const [key, value] of Object.entries(pathInformation.parameters)) {
                    parameters.push(`${key}=${value}`);
                }
                path += `?${parameters.join('&')}`;
            }
            if (project?.id !== pathInformation.project_id) navigate(path);
            else {
                navigate(path);
                eventBus.dispatch('view-comment', {
                    annotationId: pathInformation.parameters.annotation,
                    commentId: pathInformation.parameters.comment,
                });
            }
        } catch (e) {
            console.log(e);
            setError(e);
        }

        dispatch(updateNotificationState(notification, { read: true, cleared: false }));
    };

    const followReplyLink = async () => {
        try {
            const pathInformation = await DosApi.fetchReplyPath(notification.source_id);
            const slug = pathInformation.project_name
                .toString()
                .normalize('NFD') // break accented characters into components
                .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                .toLowerCase()
                .replace(/\s+/g, '-') // spaces to dashes
                .replace(/&/g, '-and-') // ampersand to and
                .replace(/[^\w-]+/g, '') // remove non-words
                .replace(/--+/g, '-') // collapse multiple dashes
                .replace(/^-+/, '') // trim starting dash
                .replace(/-+$/, ''); // trim ending dash;
            let path = `/project/${pathInformation.project_id}/${slug}`;
            if (pathInformation.parameters) {
                const parameters = [];
                for (const [key, value] of Object.entries(pathInformation.parameters)) {
                    parameters.push(`${key}=${value}`);
                }
                path += `?${parameters.join('&')}`;
            }
            navigate(path);

            if (project?.id === pathInformation.project_id)
                eventBus.dispatch('view-reply', {
                    annotationId: pathInformation.parameters.annotation,
                    commentId: pathInformation.parameters.comment,
                    replyId: pathInformation.parameters.reply,
                });
        } catch (e) {
            console.log(e);
            setError(e);
        }

        dispatch(updateNotificationState(notification, { read: true, cleared: false }));
    };

    const gotoUserAdmin = async () => {
        const goToPage = (page) => {
            if (currentPage !== page) dispatch(selectPage(page));
        };

        dispatch(updateNotificationState(notification, { read: true, cleared: false }));
        goToPage(PAGE.ADMIN);
        navigate('/admin');
    };

    const { finalContent, matches } = formatMentions(notification.message, usernames);
    useEffect(() => {
        if (matches.length > 0) {
            dispatch(checkForUsernames(matches.map((match) => match[1])));
        }
    }, [matches, dispatch]);

    const renderActionButton = () => {
        if (error) {
            return (
                <Button className="borderless green underline" onClick={setActive}>
                    Close notification
                </Button>
            );
        }
        const buttonConfig = {
            'annotation_comment': { text: 'Go to comment', onClick: followCommentLink },
            'annotation_comment_mention': { text: 'Go to comment', onClick: followCommentLink },
            'annotation_comment_reply': { text: 'Go to reply', onClick: followReplyLink },
            'annotation_comment_reply_mention': { text: 'Go to reply', onClick: followReplyLink },
            'user_registered_in_organization': { text: 'Go to user administration', onClick: gotoUserAdmin },
        };

        const config = buttonConfig[notification.type];
        if (config) {
            return (
                <Button className="borderless green underline" onClick={config.onClick}>
                    {config.text}
                    <i className="fal fa-arrow-right-long" />
                </Button>
            );
        }

        return null;
    };

    return (
        <li
            className={`notification-item ${active ? 'active' : ''} ${error ? 'error' : ''} ${color()} ${
                notification.read ? 'read' : ''
            }`}
            key={notification.id}
        >
            <div className="notification-upper grow">
                <i className={`notification-icon fal ${icon()}`} />
                <div className="notification-text grow shrink">
                    <span className="notification-timestamp">
                        {formatTimestamp(notification.timestamp)} {notification.reminded_at ? '| Reminder' : ''}
                    </span>
                    {headline()}
                    <div className="notification-content">
                        {!error ? finalContent : 'An error occurred. This comment has probably been deleted.'}
                    </div>
                </div>
            </div>
            {renderActionButton()}
            <div className="dropdown-tag-container">
                <Button className="borderless dropdown-tag" onClick={setActive}>
                    <i className={`fal fa-angle-${active ? 'up' : 'down'}`} />
                </Button>
            </div>
        </li>
    );
};

export default NotificationsListItem;
