// React
import { useDispatch, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';

import { DatasetId, SourceFileId } from 'types/common';

import * as layersSlice from 'redux/layers';

import { useEventBus } from 'EventBus';
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';
import { getUser } from 'redux/selectors';
import { getBaseName, SourceFile } from 'types/SourceFile';
import { setClickedDataset } from 'redux/actions';
import InlineDropMenu from 'components/InlineDropMenu';
import StateIndicator from 'components/StateIndicator';
import { useRef } from 'react';
import { LAYER_TYPES, PANE, SOURCE_FILE_STATES } from 'services/Constants';
import ToggleSwitch from 'components/ToggleSwitch';
import { useMountEffect } from 'components/utils';

export type Props = {
    datasetId: DatasetId;
    sourceFile: SourceFile;
    canToggleVisibility: boolean;
    onEditSourceFile: (sourceFile: SourceFile) => void;
};

const selfRelevantEqual = (sourceFileId: SourceFileId) => (left: giro3dSlice.Selection, right: giro3dSlice.Selection) =>
    (left.sourceFileId === sourceFileId) === (right.sourceFileId === sourceFileId);

const SourceFileListItem = (props: Props) => {
    const { datasetId, sourceFile, onEditSourceFile, canToggleVisibility } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const ref = useRef<HTMLLIElement>();
    const id = sourceFile.id;

    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const clickedSourceFile = useAppSelector(giro3dSlice.getSelection, selfRelevantEqual(id))?.sourceFileId;
    const eventBus = useEventBus();

    const isVisible = useAppSelector(layersSlice.getSourceFileVisibility(id));

    const setVisible = (value: boolean) => {
        dispatch(layersSlice.setSourceFileVisibility({ sourceFileId: id, value }));
    };

    const scrollTo = (event) => {
        if (event.file === id && event.scroll) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useMountEffect(
        () => eventBus.subscribe('highlight-file', scrollTo),
        () => eventBus.unsubscribe('highlight-file', scrollTo)
    );

    if (!props.sourceFile.source && props.sourceFile.state === SOURCE_FILE_STATES.UPLOADING) return null;

    function selectSourceFile() {
        dispatch(setClickedDataset(datasetId, id));
        eventBus.dispatch('highlight-file', { dataset: datasetId, file: id });
    }

    function viewSeismic() {
        selectSourceFile();
        dispatch(datasetsSlice.setSeismicDataset({ dataset: datasetId, sourceFile: id }));
        eventBus.dispatch('create-pane', { paneType: PANE.SEISMIC, showExisting: true });
    }

    function goToFile() {
        eventBus.dispatch('go-to-file', { dataset: datasetId, file: id });
    }

    return (
        <li ref={ref} className={`${clickedSourceFile === id ? 'highlight' : ''}`}>
            <div>
                <label htmlFor={`sourcefile-name-${id}`} onDoubleClick={goToFile}>
                    <button type="button" id={`sourcefile-name-${id}`} onClick={selectSourceFile} aria-label="None" />
                    {getBaseName(sourceFile)}
                </label>
                <StateIndicator id={sourceFile.id} state={sourceFile.state} state_msg={sourceFile.state_msg} />
                <InlineDropMenu id="library-dataset-actions">
                    {/* <div id={`delete-${sourceFile.id}`}>
                        <Button
                            className="borderless red"
                            id="delete-dataset"
                            title={`Delete ${getBaseName(sourceFile)}`}
                            onClick={() => setSourceFileToDelete(dataset)}
                            disabled={!dataset.user_permissions.delete_dataset && !user.is_admin}
                        >
                            <i className="fal fa-trash-can" />
                        </Button>
                    </div>
                    {!dataset.user_permissions.delete_dataset && !user.is_admin ? (
                        <UncontrolledTooltip target={`delete-${dataset.id}`}>
                            You do not have the permissions to delete this dataset
                        </UncontrolledTooltip>
                    ) : null} */}
                    {dataset.type === LAYER_TYPES.SEISMIC ? (
                        <Button
                            className="borderless light-blue"
                            id={`dataset-seismic-view-${id}`}
                            title={`View ${getBaseName(sourceFile)} in 2D`}
                            onClick={() => viewSeismic()}
                        >
                            2D
                        </Button>
                    ) : null}
                    <div id={`edit-${dataset.id}`}>
                        <Button
                            className="borderless green"
                            id="edit-dataset"
                            title={`Edit ${getBaseName(sourceFile)}`}
                            onClick={() => onEditSourceFile(sourceFile)}
                            disabled={!dataset.user_permissions.update_dataset && !user.is_admin}
                        >
                            <i className="fal fa-pen" />
                        </Button>
                    </div>
                    {!dataset.user_permissions.update_dataset && !user.is_admin ? (
                        <UncontrolledTooltip target={`edit-${dataset.id}`}>
                            You do not have the permissions to edit this dataset
                        </UncontrolledTooltip>
                    ) : null}
                </InlineDropMenu>
                {canToggleVisibility ? (
                    <ToggleSwitch
                        id={`visible-${id}`}
                        checked={isVisible}
                        onChange={(e) => setVisible(e.target.checked)}
                    />
                ) : null}
            </div>
        </li>
    );
};

export default SourceFileListItem;
