import { useState, useEffect } from 'react';

const DragAndDropArea = (props: { showFeedback: boolean; onDrop }) => {
    const [isDragging, setIsDragging] = useState(false);
    // When first hovering the overlay causes the drag to leave the area underneath it.
    // By counting to 2 we ensure that it has started the hover before it can be ended.
    let outCount = 0;

    const handleDragEnter = (e) => {
        outCount = 0;
        e.preventDefault();
        setIsDragging(props.showFeedback);
    };

    const handleDragOver = (e) => {
        if (e.target.className.includes('large-file-dropzone')) {
            e.preventDefault();
            setIsDragging(props.showFeedback);
        }
    };

    const handleDragLeave = () => {
        outCount++;
        if (outCount === 2) setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        if (e.dataTransfer.files.length === 0) return;
        const droppedFiles = Array.from(e.dataTransfer.files);
        if (props.onDrop) props.onDrop(droppedFiles);
    };

    useEffect(() => {
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);
        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
    }, [props.showFeedback]);

    return (
        <div className={`large-file-dropzone ${isDragging ? 'dragging' : ''}`} onDrop={handleDrop}>
            <div>
                <div>
                    <i className="fal fa-file-arrow-up" />
                    <br />
                    Upload Dataset
                </div>
            </div>
        </div>
    );
};

export default DragAndDropArea;
