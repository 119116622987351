import Tiles3D from '@giro3d/giro3d/entities/Tiles3D';

import { DEFAULT_POINTCLOUD_SETTINGS, LAYER_TYPES } from 'services/Constants';
import Layer, { Settings, LayerEventMap } from 'giro3d_extensions/layers/Layer';
import { LayerState, WellKnownAttributeNames } from 'types/LayerState';
import { SourceFile } from 'types/SourceFile';
import { EmptyDatasetProperties } from 'types/Dataset';
import LayerBuilder from './LayerBuilder';
import PointCloudLayer from '../layers/pointcloud/PointCloudLayer';

export default class PointcloudBuilder extends LayerBuilder<EmptyDatasetProperties> {
    protected override buildLayer(sourceFile: SourceFile): Promise<Layer<Settings, LayerEventMap, LayerState>> {
        const url = this.buildUrl(sourceFile.links.download);

        // Authorization headers are set by Giro3d as we're using a tiled source
        const pointcloud = new Tiles3D({ url, errorTarget: DEFAULT_POINTCLOUD_SETTINGS.SSE_THRESHOLD });

        pointcloud.userData.datasetId = this.getDatasetId();
        pointcloud.userData.sourceFileId = sourceFile.id;

        let defaultAttribute: string;

        switch (this.getDatasetType()) {
            case LAYER_TYPES.WATER_COLUMN:
                defaultAttribute = WellKnownAttributeNames.Intensity;
                break;
            default:
                defaultAttribute = WellKnownAttributeNames.Elevation;
                break;
        }

        const result = new PointCloudLayer({
            instance: this.getInstance(),
            layerManager: this._layerManager,
            datasetId: this.getDatasetId(),
            readableName: this.getDatasetName(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            dispatch: this._dispatch,
            entity: pointcloud,
            getFootprint: () => sourceFile.geometry,
            hostView: this._hostView,
            defaultAttribute,
        });

        return Promise.resolve(result);
    }
}
