// React
import { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { Notification } from 'types/Notification';
import { checkForUsernames, fetchNotifications } from '../../redux/actions';

// Redux
import { getNotifications } from '../../redux/selectors';

// Components
import NotificationListItem from './NotificationListItem';

const NotificationsMenu = (props: { open: boolean; toggle: () => void }) => {
    const dispatch = useAppDispatch();

    const notifications: Notification[] = useAppSelector(getNotifications) || [];

    useEffect(() => dispatch(fetchNotifications()), []);

    useEffect(() => {
        if (notifications) {
            dispatch(checkForUsernames([...new Set(notifications.map((notification) => notification.sender_id))]));
        }
    }, [notifications]);

    notifications.sort((a, b) => {
        if (a.read === b.read)
            return (
                (new Date(b.reminded_at).getTime() || new Date(b.timestamp).getTime()) -
                (new Date(a.reminded_at).getTime() || new Date(a.timestamp).getTime())
            );
        if (a.read) return 1;
        return -1;
    });
    const renderedListItems = notifications
        ? notifications.map((item) => <NotificationListItem key={`notification-${item.id}`} notification={item} />)
        : [];

    return (
        <Modal isOpen={props.open} toggle={props.toggle}>
            <ModalHeader>Notifications</ModalHeader>
            <ModalBody>
                {renderedListItems.length === 0 ? (
                    <div className="tabContent map-pane-centred-fill">
                        <i className="modal-icon fal fa-bell" />
                        <span className="map-pane-large-text">WOOHOO</span>
                        <span>You have no notifications right now</span>
                    </div>
                ) : (
                    <ul className="tabContent list">{renderedListItems}</ul>
                )}{' '}
            </ModalBody>
        </Modal>
    );
};

export default NotificationsMenu;
