import {
    createStoryView,
    createView,
    loadStoryViewById,
    loadView,
    updateProject,
    updateStoryView,
    updateView,
} from 'redux/actions';
import { SerializedState, StoryMap, VIEW_TYPE } from 'types/serialization/View';
import Project from 'types/Project';
import InlineDropMenu from 'components/InlineDropMenu';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { serialize } from 'services/serializer';
import BaseInput from 'components/forms/BaseInput';
import Checkbox from 'components/Checkbox';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useModelContext } from '../Project';

const createUrl = (view, storyId, location) => {
    const slug = view.name
        .toString()
        .normalize('NFD') // break accented characters into components
        .replace(/[\u0300-\u036f]/g, '') // remove diacritics
        .toLowerCase()
        .replace(/\s+/g, '-') // spaces to dashes
        .replace(/&/g, '-and-') // ampersand to and
        .replace(/[^\w-]+/g, '') // remove non-words
        .replace(/--+/g, '-') // collapse multiple dashes
        .replace(/^-+/, '') // trim starting dash
        .replace(/-+$/, ''); // trim ending dash;

    // searchParams.toString will encode the / before the slug so we make our own searchParam string
    // We also remove all others search params as we assume this link is only for the view

    return storyId
        ? `${window.location.origin + location.pathname}?story=${storyId}&view=${view.id}/${slug}`
        : `${window.location.origin + location.pathname}?view=${view.id}/${slug}`;
};

const copyUrl = (url, setCopyModal) => {
    navigator.clipboard.writeText(url);
    setCopyModal(true);
    setTimeout(() => setCopyModal(false), 2000);
};

export const ViewItem = (props: {
    view: SerializedState;
    project: Project;
    highlight?: boolean;
    setEditing;
    setDeleting;
    setCopyModal;
    storyId?: string;
}) => {
    const { view, project, highlight, setEditing, setDeleting, setCopyModal, storyId } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();

    return (
        <li className={highlight ? 'highlight' : ''}>
            <div>
                <label htmlFor={`view-name-${view.id}`}>
                    <button
                        id={`view-name-${view.id}`}
                        type="button"
                        onDoubleClick={() => dispatch(loadView(view.project_id, view.id))}
                    >
                        {view.id === project.default_view_id ? <i className="fas fa-globe" /> : null}
                        {view.view_type === VIEW_TYPE.PRIVATE ? <i className="fas fa-lock" /> : null}
                        <span>{view.name}</span>
                    </button>
                </label>
                <Button
                    className="borderless light-blue"
                    title="Open View"
                    onClick={() => {
                        dispatch(loadView(view.project_id, view.id));
                    }}
                >
                    <i className="fas fa-eye" />
                </Button>
                <InlineDropMenu>
                    <Button
                        className="borderless light-blue"
                        title="Copy View Link"
                        onClick={() => copyUrl(createUrl(view, storyId, location), setCopyModal)}
                    >
                        <i className="fas fa-link" />
                    </Button>
                    {project.user_permissions.update_project ? (
                        <>
                            <Button className="borderless green" title="Edit View" onClick={() => setEditing(view)}>
                                <i className="fas fa-pen" />
                            </Button>
                            {project.default_view_id !== view.id ? (
                                <Button
                                    className="borderless red"
                                    title="Delete View"
                                    onClick={() => setDeleting(view)}
                                >
                                    <i className="fas fa-trash-can" />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </InlineDropMenu>
            </div>
        </li>
    );
};

export const SortableView = (props: {
    view: SerializedState;
    story: StoryMap;
    project: Project;
    setDeleting;
    setEditing;
    setCopyModal;
    highlight?: boolean;
}) => {
    const { view, story, project, setDeleting, setEditing, setCopyModal, highlight } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: view.id });

    const dispatch = useAppDispatch();
    const location = useLocation();

    if (transform) transform.scaleY = 1;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <li className={highlight ? 'highlight' : ''} ref={setNodeRef} style={style}>
            <div>
                <Button className="borderless handle" {...listeners} {...attributes}>
                    <i className="fas fa-grip-vertical" />
                </Button>
                <label htmlFor={`view-name-${view.id}`}>
                    <button
                        id={`view-name-${view.id}`}
                        type="button"
                        onDoubleClick={() => dispatch(loadStoryViewById(view.id, story.id))}
                    >
                        <span>{view.name}</span>
                    </button>
                </label>
                <Button
                    className="borderless light-blue"
                    title="Open View"
                    onClick={() => dispatch(loadStoryViewById(view.id, story.id))}
                >
                    <i className="fas fa-eye" />
                </Button>
                <InlineDropMenu>
                    <Button
                        className="borderless light-blue"
                        title="Copy View Link"
                        onClick={() => copyUrl(createUrl(view, story.id, location), setCopyModal)}
                    >
                        <i className="fas fa-link" />
                    </Button>
                    {project.user_permissions.update_project ? (
                        <>
                            <Button className="borderless green" title="Edit View" onClick={() => setEditing(view)}>
                                <i className="fas fa-pen" />
                            </Button>
                            {project.default_view_id !== view.id ? (
                                <Button
                                    className="borderless red"
                                    title="Delete View"
                                    onClick={() => setDeleting(view)}
                                >
                                    <i className="fas fa-trash-can" />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </InlineDropMenu>
            </div>
        </li>
    );
};

export const CreateView = (props: { project: Project; setCopyModal; setCreating }) => {
    const { project, setCopyModal, setCreating } = props;

    const dispatch = useAppDispatch();
    const location = useLocation();
    const model = useModelContext();
    const state = useAppSelector((s) => s);

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    project_id: project.id,
                    view: undefined,
                    view_type: undefined,
                    setDefault: false,
                    setPrivate: false,
                }}
                onSubmit={(values) => {
                    values.project_id = project.id;
                    values.view = serialize(state, model);
                    values.view_type = values.setPrivate ? VIEW_TYPE.PRIVATE : VIEW_TYPE.SHARED;
                    dispatch(createView(values)).then((newView) => {
                        copyUrl(createUrl(newView, undefined, location), setCopyModal);
                        setCreating(undefined);
                    });
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className="sideform">
                        <BaseInput name="name" label="View Name" titled />
                        <hr />
                        <BaseInput name="description" label="View Description" long />
                        <Checkbox
                            id="setDefault"
                            label="Set Default View"
                            checked={values.setDefault}
                            onChange={(e) => setFieldValue('setDefault', e.target.checked)}
                            disabled={values.setPrivate}
                        />
                        <Checkbox
                            id="setPrivate"
                            label="Set Private View"
                            checked={values.setPrivate}
                            onChange={(e) => setFieldValue('setPrivate', e.target.checked)}
                            disabled={values.setDefault}
                        />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setCreating(null)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const EditView = (props: { view: SerializedState; project: Project; setEditing }) => {
    const { view, project, setEditing } = props;

    const dispatch = useAppDispatch();
    const model = useModelContext();
    const state = useAppSelector((s) => s);

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: view?.name,
                    description: view?.description,
                    overwrite_view: false,
                    id: view?.id,
                    project_id: view?.project_id,
                    view: undefined,
                    view_type: undefined,
                    setDefault: project.default_view_id === view?.id,
                    setPrivate: view?.view_type === VIEW_TYPE.PRIVATE,
                }}
                onSubmit={(values) => {
                    values.id = view.id;
                    values.project_id = project.id;
                    values.view_type = values.setPrivate ? VIEW_TYPE.PRIVATE : VIEW_TYPE.SHARED;
                    if (values.overwrite_view) values.view = serialize(state, model);
                    dispatch(updateView(values)).then((newView) => {
                        if (values.setDefault && project.default_view_id !== view?.id)
                            dispatch(updateProject({ ...project, default_view_id: newView.id }));
                        setEditing(undefined);
                    });
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <BaseInput name="name" label="View name" />
                        <hr />
                        <BaseInput name="description" label="View Description" long />
                        <Checkbox
                            id="overwrite_view"
                            checked={values.overwrite_view}
                            onChange={(e) => setFieldValue('overwrite_view', e.currentTarget.checked)}
                            name="overwrite_view"
                            label="Overwrite view with current settings"
                        />
                        <Checkbox
                            id="setDefault"
                            label="Set Default View"
                            checked={values.setDefault}
                            onChange={(e) => setFieldValue('setDefault', e.target.checked)}
                            disabled={project.default_view_id === view?.id || values.setPrivate}
                        />
                        <Checkbox
                            id="setPrivate"
                            label="Set Private View"
                            checked={values.setPrivate}
                            onChange={(e) => setFieldValue('setPrivate', e.target.checked)}
                            disabled={values.setDefault}
                        />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setEditing(null)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const CreateViewForStory = (props: { project: Project; story: StoryMap; setCreating }) => {
    const { project, story, setCreating } = props;

    const dispatch = useAppDispatch();
    const model = useModelContext();
    const state = useAppSelector((s) => s);

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    project_id: project.id,
                    view: undefined,
                    view_type: VIEW_TYPE.STORY,
                }}
                onSubmit={(values) => {
                    values.view = serialize(state, model);
                    dispatch(createStoryView(story, values)).then(() => setCreating(false));
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ isSubmitting }) => (
                    <Form className="sideform">
                        <BaseInput name="name" label="View Name" titled />
                        <hr />
                        <BaseInput name="description" label="View Description" long />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setCreating(false)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const EditViewForStory = (props: { view: SerializedState; story: StoryMap; setEditing }) => {
    const { view, story, setEditing } = props;

    const dispatch = useAppDispatch();
    const model = useModelContext();
    const state = useAppSelector((s) => s);

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: view?.name,
                    description: view?.description,
                    overwrite_view: false,
                    id: view?.id,
                    project_id: view?.project_id,
                    view: undefined,
                    view_type: VIEW_TYPE.STORY,
                }}
                onSubmit={(values) => {
                    values.id = view.id;
                    if (values.overwrite_view) values.view = serialize(state, model);
                    dispatch(updateStoryView(story, values)).then(() => setEditing(undefined));
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <BaseInput name="name" label="View name" />
                        <hr />
                        <BaseInput name="description" label="View Description" long />
                        <Checkbox
                            id="overwrite_view"
                            checked={values.overwrite_view}
                            onChange={(e) => setFieldValue('overwrite_view', e.currentTarget.checked)}
                            name="overwrite_view"
                            label="Overwrite view with current settings"
                        />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setEditing(undefined)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
