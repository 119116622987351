import Axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import HttpConfiguration from '@giro3d/giro3d/utils/HttpConfiguration';
import { getApiUrl } from 'config';

import type { AuthenticationResult } from '@azure/msal-browser';
import type {
    ApiKey,
    ApiVersion,
    DatasetId,
    HealthCheckResponse,
    ProjDefinition,
    ProjectId,
    ProjectLink,
    SRID,
    SsdmType,
    UUID,
} from 'types/common';
import Dataset from 'types/Dataset';
import type { Geometry } from 'geojson';
import { User } from 'types/User';
import { Organization } from 'types/Organization';
import { Membership, MembershipRequest, EmailMembershipRequest } from 'types/Membership';
import { Collection } from 'types/Collection';
import Project, { CreateProject } from 'types/Project';
import Annotation, { AnnotationComment, AnnotationCommentReply, AnnotationId, CommentId } from 'types/Annotation';
import { Notification, NotificationStatus } from 'types/Notification';
import type { SerializedState, StoryMap } from 'types/serialization/View';
import { SourceFile } from 'types/SourceFile';
import msalService from './MsalService';

const apiUrl = getApiUrl();

// we need a token to be able to start request
const axios = Axios.create({
    baseURL: apiUrl,
});

let keycloakAcquireTokenCall: Promise<AuthenticationResult>;
let keycloakCachedToken: AuthenticationResult;
let keycloakTokenRefreshTimer: NodeJS.Timeout;

function hasValidKeycloakToken() {
    return keycloakCachedToken && keycloakCachedToken.expiresOn.valueOf() - Date.now() > 0;
}
async function keycloakAcquireToken(): Promise<AuthenticationResult> {
    if (!hasValidKeycloakToken()) {
        if (!keycloakAcquireTokenCall) {
            keycloakAcquireTokenCall = msalService.acquireToken().catch((e) => {
                console.error(e);
                keycloakAcquireTokenCall = null;
                throw e;
            });
        } else {
            console.log('Acquire azure token call on its way, reusing promise');
        }
        const token = await keycloakAcquireTokenCall;
        keycloakAcquireTokenCall = null;

        // Keep track of expriration time and try to refresh
        if (!keycloakTokenRefreshTimer) {
            const msUntilUnfreshToken = token.expiresOn.valueOf() - Date.now();
            keycloakTokenRefreshTimer = setTimeout(() => {
                keycloakTokenRefreshTimer = null;
                keycloakCachedToken = null;
                keycloakAcquireToken();
            }, msUntilUnfreshToken);
            console.log(`Unfresh azure token in ${msUntilUnfreshToken / 60000} minutes`);
        }
        keycloakCachedToken = token;
    }
    return keycloakCachedToken;
}

// Careful, this is a different type than the AuthenticationResult returned by MSAL.
export type ApiAuthenticationResult = {
    access_token: string;
    expires_in: number; // In seconds
};
export type ApiToken = {
    access_token: string;
    expires_in: number; // In seconds
    expiresOn: Date;
};

let apiAcquireTokenCall: Promise<ApiAuthenticationResult>;
let apiCachedToken: ApiToken;
let apiTokenRefreshTimer: NodeJS.Timeout;

function hasValidApiToken() {
    return apiCachedToken && apiCachedToken.expiresOn.valueOf() - Date.now() > 0;
}
async function apiAcquireToken(): Promise<ApiToken> {
    if (!hasValidApiToken()) {
        if (!apiAcquireTokenCall) {
            apiAcquireTokenCall = makeApiCallKeycloak<ApiAuthenticationResult>('/auth/token/keycloak', {
                method: 'post',
                data: null,
            }).catch((e) => {
                console.error(e);
                apiAcquireTokenCall = null;
                throw e;
            });
        } else {
            console.log('Acquire api token call on its way, reusing promise');
        }
        const token = (await apiAcquireTokenCall) as ApiToken;
        apiAcquireTokenCall = null;

        // Expand with end time
        token.expiresOn = new Date(Date.now() + token.expires_in * 1000);

        // Update access token for giro3d calls
        HttpConfiguration.setHeader(apiUrl, 'Authorization', `Bearer ${token.access_token}`);

        // Keep track of expriration time and try to refresh
        if (!apiTokenRefreshTimer) {
            const msUntilUnfreshToken = token.expires_in * 1000;
            apiTokenRefreshTimer = setTimeout(() => {
                apiTokenRefreshTimer = null;
                apiCachedToken = null;
                apiAcquireToken();
            }, msUntilUnfreshToken);
            console.log(`Unfresh token in ${msUntilUnfreshToken / 60000} minutes`);
        }
        apiCachedToken = token;
    }
    return apiCachedToken;
}

/**
 * Wrapper around api calls, abstracting the little details about http queries.
 *
 * It allows you to POST/submit data either as FormData (you can get directly from forms) or json seamlessly.
 *
 * It checks for http status code and throws HTTPError if it is not 20x.
 *
 * Under the hood, it uses axios.
 *
 * Usage:
 *
 * const reticulateSplinesApi = data => makeApiCall('reticulate_splines', data, 'POST'),
 * reticulateSplines(data)
 *    .then(reticulationResult => {...})
 *    .catch(e => {... });
 *
 */

async function makeApiCallKeycloak<TResponse = void>(
    apiAction: string,
    opts: AxiosRequestConfig<unknown | null> = { method: 'get' }
): Promise<TResponse> {
    const token = await keycloakAcquireToken();

    const options: AxiosRequestConfig<unknown | null> = {
        url: apiAction,
        ...opts,
        headers: { 'Authorization': `Bearer ${token.accessToken}` },
    };

    return axios(options).then((response) => response.data as TResponse);
}

async function makeApiCallNoAuth<TResponse = void>(
    apiAction: string,
    opts: AxiosRequestConfig<unknown> = { method: 'get' }
): Promise<TResponse> {
    const options: AxiosRequestConfig<unknown> = {
        url: apiAction,
        ...opts,
    };

    return axios(options).then((response) => response.data as TResponse);
}

async function makeApiCall<TResponse = void>(
    apiAction: string,
    opts: AxiosRequestConfig<unknown> = { method: 'get' }
): Promise<TResponse> {
    const token = await apiAcquireToken();

    const options: AxiosRequestConfig<unknown> = {
        url: apiAction,
        ...opts,
        headers: { 'Authorization': `Bearer ${token.access_token}` },
    };

    return axios(options).then((response) => response.data as TResponse);
}

async function logout(): Promise<void> {
    return msalService.msalInstance
        .logoutRedirect({
            postLogoutRedirectUri: '/',
            idTokenHint: keycloakCachedToken.idToken,
        })
        .then(() => {
            apiCachedToken = null;
            keycloakCachedToken = null;
        });
}

const DosApi = {
    // Misc
    getAPIEndpoint: () => apiUrl,
    apiAcquireToken: () => apiAcquireToken(),
    logout: () => logout(),
    fetchHealthCheck: () => makeApiCallNoAuth<HealthCheckResponse>('/health/'),
    fetchVersion: () => makeApiCallNoAuth<ApiVersion>('/info/'),
    createApiKey: () => makeApiCall<ApiKey>('/auth/api-key', { method: 'post' }),
    fetchApiKeys: () => makeApiCall<ApiKey[]>('/auth/api-keys'),
    fetchAllApiKeys: () => makeApiCall<ApiKey[]>('/auth/api-keys/all'),
    deleteApiKey: (apikeyId: string) => makeApiCall<void>(`/auth/api-key/${apikeyId}`, { method: 'delete' }),

    // Users
    fetchUsers: () => makeApiCall<User[]>('/users/'),
    fetchUser: () => makeApiCall<User>('/users/me'),
    registerUser: () => makeApiCallKeycloak<void>('/users/register', { method: 'post', data: null }),
    updateUser: (userId: UUID, values: User) => makeApiCall<User>(`/users/${userId}`, { method: 'put', data: values }),
    userEligible: () => makeApiCallKeycloak<void>('/users/eligible'),
    fetchUsernames: (userIds: UUID[]) =>
        makeApiCall<User[]>('/users/names/', {
            params: { userIds },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        }),

    // CRS
    fetchProjections: (srids: SRID[]) =>
        makeApiCall<ProjDefinition[]>('/crs/', {
            params: { srids },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        }),
    fetchAllProjections: () => makeApiCall<ProjDefinition[]>('/crs/all'),

    // SSDM
    fetchSsdmTypes: () => makeApiCall<SsdmType[]>(`/ssdm/`),

    // Organizations
    fetchOrganizations: () => makeApiCall<Organization[]>(`/organizations/`),
    fetchOwnedOrganizations: () => makeApiCall<Organization[]>(`/organizations/owned`),
    createOrganization: (values: Organization) =>
        makeApiCall<Organization>(`/organizations/`, { method: 'post', data: values }),
    updateOrganization: (organizationId: UUID, values: Organization) =>
        makeApiCall<Organization>(`/organization/${organizationId}`, { method: 'patch', data: values }),
    deleteOrganization: (organizationId: UUID) =>
        makeApiCall<void>(`/organization/${organizationId}`, { method: 'delete' }),

    // Memberships
    fetchMemberships: () => makeApiCall<Membership[]>('/memberships/'),
    fetchMembershipsFor: (organizationId: UUID) =>
        makeApiCall<Membership[]>(`/organization/${organizationId}/memberships`),
    createMembership: (values: Membership) =>
        makeApiCall<Membership>(`/memberships/`, { method: 'post', data: values }),
    deleteMembership: (membershipId: UUID) => makeApiCall<void>(`/membership/${membershipId}`, { method: 'delete' }),
    updateMembership: (membershipId: UUID, values: Membership) =>
        makeApiCall<Membership>(`/membership/${membershipId}`, { method: 'patch', data: values }),
    createMembershipFromEmail: (membershipEmail: EmailMembershipRequest) =>
        makeApiCall<Membership>('/memberships/email', { method: 'post', data: membershipEmail }),
    fetchMembershipRequests: () => makeApiCall<MembershipRequest[]>('/memberships/requests'),

    // Accesses
    // TODO those endpoint are not used
    // fetchAccesses: (filters) => makeApiCall(`/accesses`, { params: filters }),
    // createAccess: (values) => makeApiCall(`/accesses/`, { method: 'post', data: values }),
    // deleteAccess: (accessId) => makeApiCall(`/access/${accessId}`, { method: 'delete' }),

    // Collections
    fetchCollections: () => makeApiCall<Collection[]>('/collections/'),
    fetchCollection: (collectionId: UUID) => makeApiCall<Collection>(`/collection/${collectionId}`),
    createCollection: (values: Collection) =>
        makeApiCall<Collection>('/collections/', { method: 'post', data: values }),
    updateCollection: (collectionId: UUID, values: Collection) =>
        makeApiCall<Collection>(`/collection/${collectionId}`, { method: 'patch', data: values }),
    deleteCollection: (collectionId: UUID) => makeApiCall<void>(`/collection/${collectionId}`, { method: 'delete' }),
    fetchDatasets: (collectionId: UUID) => makeApiCall<Dataset[]>(`/collection/${collectionId}/datasets`),

    // Datasets
    pollDatasets: (ids: DatasetId[]) => makeApiCall<Dataset[]>(`/datasets/poll`, { method: 'post', data: ids }),
    fetchAllDatasets: () => makeApiCall<Dataset[]>(`/datasets/`),
    createDataset: (values: Dataset) =>
        makeApiCall<Dataset>(`/datasets/${values.organization_id}`, { method: 'post', data: values }),
    uploadDataset: (id: DatasetId, data: FormData) =>
        makeApiCall<Dataset>(`/dataset/${id}`, {
            method: 'post',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }),
    updateDataset: (id: DatasetId, formData: FormData) =>
        makeApiCall<Dataset>(`/dataset/${id}`, {
            method: 'patch',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }),
    fetchDataset: (id: DatasetId) => makeApiCall<Dataset>(`/dataset/${id}`),
    fetchDatasetSourcefiles: (id: DatasetId) => makeApiCall<SourceFile[]>(`/dataset/${id}/sourcefiles`),
    pollDatasetSourcefiles: (datasetId: DatasetId, sourcefileIds: string[]) =>
        makeApiCall<SourceFile[]>(`/dataset/${datasetId}/sourcefiles/poll`, { method: 'post', data: sourcefileIds }),
    deleteDatasetSourcefiles: (datasetId: DatasetId, sourcefileIds: string[]) =>
        makeApiCall<void>(`/dataset/${datasetId}/sourcefiles/?id=${sourcefileIds.join('&id=')}`, { method: 'delete' }),
    deleteDataset: (datasetId: DatasetId) => makeApiCall<void>(`/dataset/${datasetId}`, { method: 'delete' }),
    deleteDatasets: (datasetIds: DatasetId[]) =>
        makeApiCall<void>(`/datasets/?id=${datasetIds.join('&id=')}`, { method: 'delete' }),

    // Seismic
    fetchVDSSlice: (
        sourcePath: string,
        lod: number,
        startTrace: number,
        numTraces: number,
        startSample: number,
        numSamples: number,
        signal: AbortSignal
    ) =>
        makeApiCall<ArrayBuffer>(
            `/seismic/slice/${sourcePath}?lod=${lod}&start_trace=${startTrace}&nr_traces=${numTraces}&start_sample=${startSample}&nr_samples=${numSamples}`,
            {
                responseType: 'arraybuffer',
                signal,
            }
        ),

    // Projects
    fetchProject: (projectId: ProjectId) => makeApiCall<Project>(`/project/${projectId}`),
    fetchProjects: () => makeApiCall<Project[]>('/projects/'),
    createProject: (values: CreateProject) => makeApiCall<Project>('/projects/', { method: 'post', data: values }),
    updateProject: (projectId: ProjectId, values: Project) =>
        makeApiCall<Project>(`/project/${projectId}`, { method: 'patch', data: values }),
    deleteProject: (projectId: ProjectId) => makeApiCall<void>(`/project/${projectId}`, { method: 'delete' }),
    fetchProjectDatasetsList: (projectId: ProjectId) => makeApiCall<Dataset[]>(`/project/${projectId}/datasets/list`),
    fetchProjectDatasets: (projectId: ProjectId) => makeApiCall<Dataset[]>(`/project/${projectId}/datasets`),
    relateProjectDataset: (projectId: ProjectId, datasetId: DatasetId) =>
        makeApiCall<void>(`/project/${projectId}/datasets`, { method: 'post', data: { dataset_id: datasetId } }),
    unrelateProjectDataset: (projectId: ProjectId, datasetId: DatasetId) =>
        makeApiCall<void>(`/project/${projectId}/datasets/?id=${datasetId}`, { method: 'delete' }),
    // TODO this is not used
    // uploadProjectFiles: (projectId: ProjectId, data) =>
    //     makeApiCall(`/project/${projectId}/files`, {
    //         method: 'post',
    //         data,
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     }),
    // TODO this is not used
    // uploadProjectFlyer: (projectId: ProjectId, data) =>
    //     makeApiCall(`/project/${projectId}/flyer`, {
    //         method: 'post',
    //         data,
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     }),

    openDocument: (url: string) =>
        makeApiCall<Blob>(url, { responseType: 'blob' })
            .then((blob) => {
                // create local url
                const _url = window.URL.createObjectURL(blob);
                window.open(_url, '', 'width=600, height=400, left=200, top=200');
            })
            .catch((err) => {
                console.log(err);
            }),

    // Notifications
    fetchNotifications: () => makeApiCall<Notification[]>('/notifications/'),
    updateNotificationState: (notificationId: UUID, notificationStatus: NotificationStatus) =>
        makeApiCall<Notification>(`notification/${notificationId}`, { method: 'patch', data: notificationStatus }),

    // Annotations
    deleteAnnotation: (projectId: ProjectId, annotationId: AnnotationId) =>
        makeApiCall<void>(`/project/${projectId}/annotations/${annotationId}`, { method: 'delete' }),
    fetchAnnotations: (projectId: ProjectId) => makeApiCall<Annotation[]>(`project/${projectId}/annotations`),
    createAnnotation: (projectId: ProjectId, values: Partial<Annotation>, geometry: Geometry) =>
        makeApiCall<Annotation>(`project/${projectId}/annotations`, { method: 'post', data: { ...values, geometry } }),
    editAnnotation: (
        projectId: ProjectId,
        annotationId: AnnotationId,
        values: Partial<Annotation>,
        geometry?: Geometry
    ) =>
        makeApiCall<Annotation>(`project/${projectId}/annotations/${annotationId}`, {
            method: 'patch',
            data: { ...values, geometry },
        }),
    postComment: (projectId: ProjectId, annotationId: AnnotationId, comment: Partial<AnnotationComment>) =>
        makeApiCall<AnnotationComment>(`/project/${projectId}/annotations/${annotationId}/comments`, {
            method: 'post',
            data: comment,
        }),
    fetchComments: (projectId: ProjectId, annotationId: AnnotationId) =>
        makeApiCall<AnnotationComment[]>(`/project/${projectId}/annotations/${annotationId}/comments`),
    deleteComment: (projectId: ProjectId, annotationId: AnnotationId, commentId: CommentId) =>
        makeApiCall<void>(`/project/${projectId}/annotations/${annotationId}/comments/${commentId}`, {
            method: 'delete',
        }),
    fetchProjectUsers: (projectId: ProjectId) => makeApiCall<User[]>(`project/${projectId}/usernames`),
    updateComment: (
        projectId: ProjectId,
        annotationId: AnnotationId,
        commentId: CommentId,
        data: Partial<AnnotationComment>
    ) =>
        makeApiCall<AnnotationComment>(`/project/${projectId}/annotations/${annotationId}/comments/${commentId}`, {
            method: 'patch',
            data,
        }),
    relateAnnotationDataset: (projectId: ProjectId, annotationId: AnnotationId, datasetId: DatasetId) =>
        makeApiCall<void>(`project/${projectId}/annotations/${annotationId}/datasets`, {
            method: 'post',
            data: { dataset_id: datasetId },
        }),
    unrelateAnnotationDataset: (projectId: ProjectId, annotationId: AnnotationId, datasetId: DatasetId) =>
        makeApiCall<void>(`project/${projectId}/annotations/${annotationId}/datasets/${datasetId}`, {
            method: 'delete',
        }),

    fetchCommentPath: (commentId: CommentId) => makeApiCall<ProjectLink>(`/comment/${commentId}`),
    fetchReplyPath: (replyId: CommentId) => makeApiCall<ProjectLink>(`/reply/${replyId}`),
    postReply: (
        projectId: ProjectId,
        annotationId: AnnotationId,
        commentId: CommentId,
        reply: Partial<AnnotationCommentReply>
    ) =>
        makeApiCall<AnnotationCommentReply>(
            `/project/${projectId}/annotations/${annotationId}/comments/${commentId}/replies`,
            {
                method: 'post',
                data: reply,
            }
        ),
    updateReply: (
        projectId: ProjectId,
        annotationId: AnnotationId,
        commentId: CommentId,
        replyId: CommentId,
        data: Partial<AnnotationCommentReply>
    ) =>
        makeApiCall<AnnotationCommentReply>(
            `/project/${projectId}/annotations/${annotationId}/comments/${commentId}/replies/${replyId}`,
            {
                method: 'patch',
                data,
            }
        ),
    deleteReply: (projectId: ProjectId, annotationId: AnnotationId, commentId: CommentId, replyId: CommentId) =>
        makeApiCall<void>(
            `/project/${projectId}/annotations/${annotationId}/comments/${commentId}/replies/${replyId}`,
            {
                method: 'delete',
            }
        ),

    // Saved views
    createView: (project: Project, view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${project.id}/view/`, { method: 'post', data: view }),
    fetchViews: (projectId: ProjectId) => makeApiCall<SerializedState[]>(`/project/${projectId}/views`),
    fetchView: (projectId: ProjectId, viewId: UUID) =>
        makeApiCall<SerializedState>(`/project/${projectId}/view/${viewId}`),
    updateView: (view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${view.project_id}/view/${view.id}`, { method: 'patch', data: view }),
    deleteView: (view: SerializedState) =>
        makeApiCall<void>(`/project/${view.project_id}/view/${view.id}`, { method: 'delete' }),
    createLastSessionView: (project: Project, view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${project.id}/view/last_session`, { method: 'post', data: view }),
    updateLastSessionView: (view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${view.project_id}/view/last_session`, { method: 'patch', data: view }),
    fetchLastSessionView: (projectId: ProjectId) =>
        makeApiCall<SerializedState>(`/project/${projectId}/view/last_session`),

    // Stories
    createStory: (projectId: ProjectId, story: StoryMap) =>
        makeApiCall<StoryMap>(`/project/${projectId}/story/`, { method: 'post', data: story }),
    fetchStories: (projectId: ProjectId) => makeApiCall<StoryMap[]>(`/project/${projectId}/stories`),
    fetchStory: (projectId: ProjectId, storyId: UUID) =>
        makeApiCall<StoryMap>(`/project/${projectId}/story/${storyId}`),
    deleteStory: (story: StoryMap) =>
        makeApiCall<void>(`project/${story.project_id}/story/${story.id}`, { method: 'delete' }),
    updateStory: (story: StoryMap) =>
        makeApiCall<StoryMap>(`project/${story.project_id}/story/${story.id}`, { method: 'patch', data: story }),
    addStoryView: (story: StoryMap, view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${story.project_id}/story/${story.id}`, { method: 'post', data: view }),
    updateStoryView: (story: StoryMap, view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${story.project_id}/story/${story.id}/view/${view.id}`, {
            method: 'patch',
            data: view,
        }),
    deleteStoryView: (story: StoryMap, view: SerializedState) =>
        makeApiCall<SerializedState>(`/project/${story.project_id}/story/${story.id}/view/${view.id}`, {
            method: 'delete',
        }),
    updateStoryOrder: (story: StoryMap, order: UUID[]) =>
        makeApiCall<StoryMap>(`/project/${story.project_id}/story/${story.id}/order`, {
            method: 'patch',
            data: { viewIds: order },
        }),

    // Note: Elevation profile api endpoint is not usable after ceph migration
    // getElevationProfile: (
    //     datasetId: DatasetId,
    //     coords: {
    //         point1: { x: number; y: number };
    //         point2: { x: number; y: number };
    //         samples: number;
    //     } // TODO better typing of return type
    // ) => makeApiCall<object>(`/dataset/${datasetId}/profile`, { method: 'post', data: coords }),
};
export default DosApi;
