import * as THREE from 'three';
import { GlobalCache } from '@giro3d/giro3d/core/Cache';
import { DatasetId } from 'types/common';
import Source, { delay, ReadRequest } from './Source';
import DosApi from '../../../services/DosApi';
import SeismicPlaneRegion from './SeismicPlaneRegion';

interface VdsProperties {
    numTraces: number;
    numSamples: number;
    lods: number;
    chunkSize: number;
}

/**
 * Loads data from a remote vds endpoint.
 */
export default class VdsSource extends Source {
    private readonly sourcePath: string;
    private readonly vdsCapabilities: VdsProperties;
    private readonly resolutions: { resolution: number; lod: number }[];
    private readonly datasetId: DatasetId;

    /**
     * @param sourcePath The relative source file path to the seismic file.
     * @param properties Properties of vds file.
     * @param heightMeters The height of the dataset, in meters.
     */
    constructor(sourcePath: string, properties: VdsProperties, heightMeters: number) {
        super();
        this.sourcePath = sourcePath;

        this.vdsCapabilities = properties;

        this.resolutions = [];

        for (let i = 0; i < this.levels; i++) {
            const pixels = this.height / 2 ** i;
            const pixelSize = heightMeters / pixels;
            this.resolutions.push({ resolution: pixelSize, lod: i });
        }
        this.resolutions.reverse();
    }

    /**
     * The width in pixels of the seismic raster.
     */
    get width() {
        return this.vdsCapabilities.numTraces;
    }

    /**
     * The height in pixels of the seismic raster.
     */
    get height() {
        return this.vdsCapabilities.numSamples;
    }

    get chunkSize() {
        return this.vdsCapabilities.chunkSize;
    }

    get levels() {
        return this.vdsCapabilities.lods;
    }

    getTileSize(lod: number) {
        return this.vdsCapabilities.chunkSize * 2 ** lod;
    }

    getLod(resolution: number) {
        for (let i = 0; i < this.resolutions.length; i++) {
            if (resolution >= this.resolutions[i].resolution) {
                return this.resolutions[i].lod;
            }
        }

        return 0;
    }

    /**
     * Convert the plane region into a VDS trace/sample region, with a margin applied.
     * @param region The plane region.
     * @returns The VDS regions (in samples/traces) and the adjusted plane region.
     */
    private getVdsRegion(region: SeismicPlaneRegion): {
        startTrace: number;
        startSample: number;
        numTraces: number;
        numSamples: number;
        adjustedRegion: SeismicPlaneRegion;
    } {
        const rawStartTrace = Math.floor(region.u * this.width);
        const rawStartSample = Math.floor(region.v * this.height);
        const rawNumTraces = Math.ceil(region.width * this.width);
        const rawNumSamples = Math.ceil(region.height * this.height);

        // The margin is required to reduce visual artifacts at the edges of tiles.
        // In other word, the texture must be slightly bigger than the tile so that the pixels
        // that are on the edges of the tile are not on the edge of the texture.
        const MARGIN = 10;
        const startTrace = Math.max(0, rawStartTrace - MARGIN);
        const startSample = Math.max(0, rawStartSample - MARGIN);
        const lastTrace = Math.min(startTrace + rawNumTraces + MARGIN * 2, this.width);
        const lastSample = Math.min(startSample + rawNumSamples + MARGIN * 2, this.height);
        const numTraces = lastTrace - startTrace;
        const numSamples = lastSample - startSample;

        const u = startTrace / this.width;
        const v = startSample / this.height;
        const w = numTraces / this.width;
        const h = numSamples / this.height;

        const adjustedRegion = new SeismicPlaneRegion(u, v, w, h);

        return {
            startTrace,
            startSample,
            numTraces,
            numSamples,
            adjustedRegion,
        };
    }

    /**
     * @param request The request.
     * @returns  The texture and adjusted region.
     */
    async read(request: ReadRequest): Promise<{ texture: THREE.DataTexture; adjustedRegion: SeismicPlaneRegion }> {
        request.signal.throwIfAborted();
        await delay(100);
        request.signal.throwIfAborted();

        // Let's find the LOD that matches the most closely the requested resolution
        // NOTE: Since the entity anyway need to know the LOD of the tile, I think this computation can be moved from here.
        const resolution = request.tileSizeMeters.height / request.outputSize.height;
        const lod = this.getLod(resolution);

        const { startTrace, numTraces, startSample, numSamples, adjustedRegion } = this.getVdsRegion(request.region);

        const key = `${this.datasetId}-${lod}-${startTrace}-${startSample}-${numTraces}-${numSamples}`;
        let intensityData = GlobalCache.get(key) as Uint8ClampedArray;

        if (!intensityData) {
            const imageBlob = await DosApi.fetchVDSSlice(
                this.sourcePath,
                lod,
                startTrace,
                numTraces,
                startSample,
                numSamples,
                request.signal
            );
            intensityData = new Uint8ClampedArray(imageBlob);
            GlobalCache.set(key, intensityData, { size: intensityData.byteLength });
        }

        request.signal.throwIfAborted();

        const width = Math.ceil(numTraces / 2 ** lod);
        const height = Math.ceil(numSamples / 2 ** lod);

        const texture = new THREE.DataTexture(intensityData, width, height, THREE.RedFormat, THREE.UnsignedByteType);
        texture.needsUpdate = true;

        // When the texture is uploaded to the GPU, get rid of the buffer on the CPU side.
        // This way, the only place where this buffer exists is the cache (if the cache is enabled).
        // When the cache decides to remove it, it's gone forever and memory is freed.
        texture.onUpdate = function onUpdate() {
            texture.image.data = null;
        };

        return { texture, adjustedRegion };
    }
}
