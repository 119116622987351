import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { getPage } from 'redux/selectors';
import { PAGE } from 'services/Constants';
import { Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import logo from '../images/symbol.svg';

const HomeButton = () => {
    const navigate = useNavigate();
    const page = useAppSelector(getPage);

    const [confirmModal, setConfirmModal] = useState(false);

    return (
        <>
            <button
                type="button"
                className="homeButton"
                id="home-button"
                onClick={page === PAGE.PROJECT ? () => setConfirmModal(true) : () => navigate('/')}
            >
                <img src={logo} className="toolbar-logo" alt="Argeo logo" draggable={false} />
                <UncontrolledTooltip placement="bottom" target="home-button" fade={false} hideArrow>
                    <span className="menu-tooltip">Return to Overview</span>
                </UncontrolledTooltip>
            </button>
            <Modal centered className="modal-confirm" isOpen={confirmModal}>
                <ModalBody>
                    <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                    <span className="big-modal-text">Are you sure you want to leave this project?</span>
                    <span className="small-modal-text">Unsaved changes will be lost.</span>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="pane-button large highlight" onClick={() => navigate('/')}>
                        Leave the Project
                    </button>
                    <button type="button" className="pane-button large" onClick={() => setConfirmModal(false)}>
                        Return to Project
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default HomeButton;
