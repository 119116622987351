import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Annotation, { AnnotationComment, AnnotationCommentReply, AnnotationId } from 'types/Annotation';
import { useAppDispatch } from 'store';
import Select, { Option } from 'components/Select';
import { postComment, postReply } from '../../../redux/actions';
import { getProjectUsers } from '../../../redux/selectors';

export type Props = {
    annotation: Annotation;
    parent_id?: AnnotationId;
    onSubmit?: () => void;
};

const CommentForm = (props: Props) => {
    const dispatch = useAppDispatch();

    const [submitting, setSumbitting] = useState(false);
    const [mentioning, setMentioning] = useState(false);

    const users = useSelector(getProjectUsers);

    const ref = useRef<HTMLDivElement>();

    const mentionRegex =
        /@\(([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}) \| .*?\)/g;

    const submit = () => {
        setSumbitting(true);
        if (ref.current.innerText === '') {
            setSumbitting(false);
            if (props.onSubmit) props.onSubmit();
        } else if (props.parent_id) {
            const reply: Partial<AnnotationCommentReply> = {
                comment_id: props.parent_id,
                content: ref.current.innerText,
                mentions: [...ref.current.innerText.matchAll(mentionRegex)].map((match) => match[1]),
            };
            dispatch(postReply(props.annotation, props.parent_id, reply)).then(() => {
                ref.current.innerText = '';
                setSumbitting(false);
                if (props.onSubmit) props.onSubmit();
            });
        } else {
            const comment: Partial<AnnotationComment> = {
                annotation_id: props.annotation.id,
                content: ref.current.innerText,
                resolved: false,
                mentions: [...ref.current.innerText.matchAll(mentionRegex)].map((match) => match[1]),
            };
            dispatch(postComment(props.annotation, comment)).then(() => {
                ref.current.innerText = '';
                setSumbitting(false);
                if (props.onSubmit) props.onSubmit();
            });
        }
    };

    return (
        <div className={`comment-form ${props.parent_id ? 'indent' : ''}`}>
            <div
                placeholder={`Leave a ${props.parent_id ? 'reply' : 'comment'}…`}
                className={`comment-field ${submitting ? 'faded-text' : ''}`}
                role="textbox"
                id={`comment-field-${props.parent_id}`}
                aria-label="comment field"
                contentEditable={!submitting}
                suppressContentEditableWarning
                ref={ref}
                tabIndex={0}
            />
            <div className="comment-control-row">
                <div className="comment-controls">
                    <Button
                        className="borderless"
                        id="mention"
                        title="Mention"
                        onClick={() => setMentioning(!mentioning)}
                    >
                        <i className="comment-control fal fa-at" />
                    </Button>
                    {mentioning ? (
                        <Select
                            options={users.map((user) => ({
                                value: user.id,
                                label: `${user.given_name} ${user.family_name}`,
                            }))}
                            placeholder="Select a user to mention..."
                            autoFocus
                            onChange={(val) => {
                                ref.current.textContent += `@(${(val as Option<string>).value} | ${(val as Option<string>).label}) `;
                                setMentioning(false);
                            }}
                            isSearchable
                            className="mentionselect"
                        />
                    ) : null}
                </div>
                <Button className="borderless" id="send" title="Send" onClick={submit}>
                    <i className="comment-send fal fa-paper-plane-top" />
                </Button>
            </div>
        </div>
    );
};

export default CommentForm;
