import * as giro3dSlice from 'redux/giro3d';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from 'store';
import AnnotationViewer from './annotationMenu/AnnotationViewer';
import FeatureInfo from './featureInfo/FeatureInfo';
import InspectDatasetMenu from './datasetsMenu/sourceFilesMenu/InspectDatasetMenu';

const ActiveSelection = (props: { short?: boolean }) => {
    const selection = useAppSelector(giro3dSlice.getSelection, shallowEqual);

    if (selection?.feature) return <FeatureInfo />;
    if (selection?.annotationId) return <AnnotationViewer annotationId={selection.annotationId} temporary />;
    if (selection?.datasetId)
        return <InspectDatasetMenu datasetId={selection.datasetId} temporary short={props.short} />;
    return <span className="tabContent">No Active Selection</span>;
};

export default ActiveSelection;
