import { SourceFile } from 'types/SourceFile';
import { ConstructorParams } from 'giro3d_extensions/layers/seismic/SeismicPlaneLayer';
import SeismicPlaneBuilder from './SeismicPlaneBuilder';
import SeismicPlane2dLayer from '../layers/seismic/SeismicPlane2dLayer';

export default class SeismicPlane2dBuilder extends SeismicPlaneBuilder<SeismicPlane2dLayer> {
    private _flat: boolean;

    protected override buildLayer(sourceFile: SourceFile): Promise<SeismicPlane2dLayer> {
        const layer = new SeismicPlane2dLayer(this.getParams(sourceFile));

        return Promise.resolve(layer);
    }

    setFlat(flat: boolean) {
        this._flat = flat;
    }

    getParams(sourceFile: SourceFile): ConstructorParams {
        return { ...super.getParams(sourceFile), flat: this._flat };
    }
}
