// React
import { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import { supportedTypesLabels, supportedDataTypesLabels } from 'services/Labels';
import * as datasetsSlice from 'redux/datasets';
import { getCollections, getOrganizations, getUser } from 'redux/selectors';

// API
import DosApi from 'services/DosApi';

// Components
import ErrorBoundary from '../ErrorBoundary';

import UploadFilesModalForm from '../giro3d/forms/UploadFilesModalForm';

import SourceFileTable from '../giro3d/datasetsMenu/SourceFileTable';
import EditDataset from './EditDataset';

const InspectDatasetModal = ({ datasetId, onClose }) => {
    const dispatch = useAppDispatch();

    const user = useAppSelector(getUser);
    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(datasetId));
    const organizations = useAppSelector(getOrganizations);
    const collections = useAppSelector(getCollections);

    const [uploadFilesModal, setUploadFilesModal] = useState(false);
    const [editDatasetModal, setEditDatasetModal] = useState(false);

    const toggleUploadFilesModal = () => {
        setUploadFilesModal(!uploadFilesModal);
    };
    const toggleEditDatasetModal = () => {
        setEditDatasetModal(!editDatasetModal);
    };

    const onEditDatasetClose = () => {
        toggleEditDatasetModal();
        // TODO: See if this needs to update dataset state to trigger polling
    };
    const onDatasetFileUploadClose = () => {
        toggleUploadFilesModal();
        // TODO: See if this needs to update dataset state to trigger polling
    };

    // const formatPropertyValue = (value) => {
    //     if (!(value instanceof Object)) {
    //         return Number.isNaN(value) || Number.isInteger(value) ? value : Number(value).toFixed(2);
    //     }
    //     return 'Not shown';
    // };
    // const formatProperties = (properties) => {
    //     return (
    //         <table>
    //             {Object.entries(properties).map(([key, value]) => (
    //                 <tr key={key}>
    //                     <td>{key}</td>
    //                     <td>{formatPropertyValue(value)}</td>
    //                 </tr>
    //             ))}
    //         </table>
    //     );
    // };
    useEffect(() => {
        if (!dataset)
            DosApi.fetchDataset(datasetId)
                .then((freshDataset) => {
                    dispatch(datasetsSlice.addDataset(freshDataset));
                })
                .catch((err) => console.log(err));
    }, [dataset]);

    useEffect(() => {
        if (dataset && !sourcefiles)
            DosApi.fetchDatasetSourcefiles(dataset.id)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.addDataset(dataset));
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    }, [dataset, sourcefiles]);

    if (dataset === null)
        return (
            <div className="map-pane-title-bar">
                <div className="map-pane-title">No dataset selected</div>
            </div>
        );

    return (
        <>
            <ModalHeader toggle={onClose}>
                {dataset.name}
                <div className="pane-list-item-subtitle">{dataset.id}</div>
            </ModalHeader>
            <ModalBody>
                <div className="map-pane-body">
                    <ErrorBoundary
                        dispatch={dispatch}
                        fallback={
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the sourcefile list.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                        }
                    >
                        <>
                            {/* Dataset info */}
                            <div className="map-pane-list-title-bar">
                                <div className="map-pane-list-title">Metadata</div>
                                <Button
                                    className="borderless green"
                                    id={`dataset-inspect-edit-${dataset.id}`}
                                    title="Edit dataset"
                                    onClick={toggleEditDatasetModal}
                                >
                                    <i className="fal fa-pen" />
                                </Button>
                                {!dataset.user_permissions.update_dataset && !user.is_admin ? (
                                    <UncontrolledTooltip target={`dataset-inspect-edit-${dataset.id}`}>
                                        You do not have the permissions to edit this dataset
                                    </UncontrolledTooltip>
                                ) : null}
                            </div>
                            <div>
                                <table className="table firstColumn">
                                    <tbody>
                                        <tr>
                                            <td>Projection</td>
                                            <td>EPSG:{dataset.projection}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{supportedTypesLabels[dataset.type]}</td>
                                        </tr>
                                        <tr>
                                            <td>Data type</td>
                                            <td>{supportedDataTypesLabels[dataset.datatype]}</td>
                                        </tr>
                                        <tr>
                                            <td>Owner</td>
                                            <td>
                                                {organizations
                                                    ? organizations.find((e) => e.id === dataset.organization_id)
                                                          ?.display_name
                                                    : 'Loading...'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Collection</td>
                                            <td>
                                                {collections
                                                    ? collections.find((e) => e.id === dataset.collection_id)?.name ||
                                                      'No collection'
                                                    : 'Loading...'}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>Properties</td>
                                            <td>{formatProperties(dataset.properties)}</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            {/* Files list */}
                            <div className="map-pane-list-title-bar">
                                <div className="map-pane-list-title">Files</div>
                                <Button
                                    className="borderless light-blue"
                                    id={`dataset-inspect-upload-${dataset.id}`}
                                    title="Upload files"
                                    onClick={toggleUploadFilesModal}
                                >
                                    <i className="fal fa-upload" />
                                </Button>
                                {!dataset.user_permissions.update_dataset && !user.is_admin ? (
                                    <UncontrolledTooltip target={`dataset-inspect-upload-${dataset.id}`}>
                                        You do not have the permissions to edit this dataset
                                    </UncontrolledTooltip>
                                ) : null}
                            </div>
                            <div>
                                {sourcefiles ? <SourceFileTable dataset={dataset} /> : 'Loading...'}
                                {sourcefiles?.length === 0 && 'No files yet'}
                            </div>
                        </>
                    </ErrorBoundary>
                </div>
            </ModalBody>

            <Modal isOpen={uploadFilesModal} toggle={toggleUploadFilesModal}>
                <UploadFilesModalForm dataset={dataset} onClose={onDatasetFileUploadClose} />
            </Modal>

            <Modal isOpen={editDatasetModal} toggle={toggleEditDatasetModal}>
                <EditDataset onClose={onEditDatasetClose} openDataset={dataset} />
            </Modal>
        </>
    );
};

export default InspectDatasetModal;
