import { Euler, MathUtils, Quaternion, Vector2, Vector3 } from 'three';

import './compass.css';
import type Instance from '@giro3d/giro3d/core/Instance';
import Controls, { CONTROLS_MODE } from 'services/Controls';

const tmpvec2 = new Vector2();
const tmpvec3 = new Vector3();
const tmpvec2to3 = new Vector3();

const X = new Vector3(1, 0, 0);
const Y = new Vector3(0, 1, 0);
const Z = new Vector3(0, 0, 1);

class Compass {
    private readonly instance: Instance;
    private readonly domElement: HTMLDivElement;
    private readonly controls: Controls;
    private readonly _dragStartPosition: Vector2;
    private readonly _lastDragPosition: Vector2;

    private readonly _updateCompass;

    private _currentDraggingDirection: null;
    private _isDragging: boolean;
    private _startedDragging: boolean;

    constructor(instance: Instance, controls: Controls, controlsDomElement: HTMLElement, opts = { size: '200px' }) {
        this.instance = instance;
        this.controls = controls;

        this.domElement = document.createElement('div');
        this.domElement.className = 'compass_widget';
        this.domElement.style.setProperty('--size', opts.size);
        this.domElement.style.setProperty('--label-size', '24px');
        this.domElement.innerHTML = `<div class="compass">
    <div class="compass_circle"></div>
    <div class="compass_direction south coordinate-component y" title="View from South">S</div>
    <div class="compass_direction east coordinate-component x" title="View from East">E</div>
    <div class="compass_direction north coordinate-component y" title="View from North">N</div>
    <div class="compass_direction west coordinate-component x" title="View from West">W</div>
    <div class="compass_direction up coordinate-component z" title="View from top">Z</div>
    <div class="compass_direction down coordinate-component z" title="View from bottom">Z-</div>

    <div class="compass_line x coordinate-component"></div>
    <div class="compass_line-cross x coordinate-component"></div>
    <div class="compass_line y coordinate-component"></div>
    <div class="compass_line-cross y coordinate-component"></div>
    <div class="compass_line z coordinate-component"></div>
    <div class="compass_line-cross z coordinate-component"></div>
</div>`;

        controlsDomElement.append(this.domElement);

        this.bind('north');
        this.bind('west');
        this.bind('east');
        this.bind('south');
        this.bind('up');
        this.bind('down');

        this._updateCompass = this.updateCompass.bind(this);

        // Bind event to update compass
        instance.addEventListener('before-camera-update', this._updateCompass);

        this.calculateCurrentOrientation();
        this._startedDragging = false;
        this._isDragging = false;
        this._currentDraggingDirection = null;
        this._dragStartPosition = new Vector2();
        this._lastDragPosition = new Vector2();
    }

    updateCompass() {
        const orientation = this.calculateCurrentOrientation();
        this.domElement.style.setProperty('--theta', `${orientation.theta}deg`);
        this.domElement.style.setProperty('--phi', `${-orientation.phi}deg`);
    }

    bind(direction) {
        const domElement = this.domElement.getElementsByClassName(`compass_direction ${direction}`)[0];

        domElement.addEventListener('mousedown', (e: MouseEvent) => {
            if (this._startedDragging) {
                console.warn('We are already dragging, something is wrong');
                return;
            }

            this._currentDraggingDirection = direction;
            const pointer = {
                pointerId: 0,
                clientX: e.clientX,
                clientY: e.clientY,
                deltaX: 0,
                deltaY: 0,
            };

            tmpvec2.set(pointer.clientX, pointer.clientY);
            this._dragStartPosition.copy(tmpvec2);
            this._lastDragPosition.copy(tmpvec2);

            switch (this._currentDraggingDirection) {
                case 'up':
                case 'down':
                    this.domElement.getElementsByClassName('compass_direction up')[0].classList.add('active');
                    this.domElement.getElementsByClassName('compass_direction down')[0].classList.add('active');
                    break;
                case 'north':
                case 'south':
                    this.domElement.getElementsByClassName('compass_direction north')[0].classList.add('active');
                    this.domElement.getElementsByClassName('compass_direction south')[0].classList.add('active');
                    break;
                case 'east':
                case 'west':
                    this.domElement.getElementsByClassName('compass_direction east')[0].classList.add('active');
                    this.domElement.getElementsByClassName('compass_direction west')[0].classList.add('active');
                    break;
                default:
                /* do nothing */
            }

            this._startedDragging = true;
            this._isDragging = false;
            this.controls.cameraControls.dispatchEvent({ type: 'controlstart' });

            const documentMouseMove = this.onMouseMoveDraggingForward.bind(this);
            this.instance.viewport.ownerDocument.addEventListener('mousemove', documentMouseMove);

            // Bind mouseup on document so we end dragging even if we're outside of the viewport
            const documentMouseup = this.onMouseUpEndDraggingForward.bind(this);
            this.instance.viewport.ownerDocument.addEventListener('mouseup', documentMouseup);

            // drawTools capture mouseup on document when adding a point
            // not sure why native camera-controls are not affected, but here's a workaround
            const viewportMouseUp = this.onMouseUpEndDraggingForward.bind(this);
            this.instance.viewport.addEventListener('mouseup', viewportMouseUp);
        });
    }

    /**
     * Event handler to handle dragging in Dolly mode
     * @param {MouseEvent} e Event
     */
    onMouseMoveDraggingForward(e) {
        if (!this._startedDragging) return;

        if (this.controls.mode === CONTROLS_MODE.FOLLOW) return;

        const factor = this.controls.cameraControls.truckSpeed * (this.controls.cameraControls.distance / 1000);
        const pointer = {
            pointerId: 0,
            clientX: e.clientX,
            clientY: e.clientY,
            deltaX: e.movementX,
            deltaY: e.movementY,
        };

        const orientation = this.calculateCurrentOrientation();

        tmpvec2.set(this._lastDragPosition.x - pointer.clientX, this._lastDragPosition.y - pointer.clientY);
        if (tmpvec2.x === 0 && tmpvec2.y === 0) return;

        this._isDragging = true;
        tmpvec2to3.set(tmpvec2.x, tmpvec2.y, 0);

        // Find out what the 2D coordinates corresponds to in our 3D world
        // For that, we apply back the rotations from the compass
        // There might be an easier and more elegant solution, but at least it works
        switch (this._currentDraggingDirection) {
            case 'up':
            case 'down':
                tmpvec2to3.applyAxisAngle(Y, -Math.PI * 0.5);
                break;
            case 'north':
            case 'south':
                tmpvec2to3.applyAxisAngle(Z, -Math.PI * 0.5);
                break;
            case 'east':
            case 'west':
                /* do nothing */
                break;
            default:
            /* do nothing */
        }

        tmpvec2to3.applyAxisAngle(X, -Math.PI * 0.5);
        tmpvec2to3.applyAxisAngle(Y, MathUtils.degToRad(-orientation.phi));
        tmpvec2to3.applyAxisAngle(X, MathUtils.degToRad(-orientation.theta));

        this.controls.getTarget(tmpvec3);

        switch (this._currentDraggingDirection) {
            case 'up':
            case 'down':
                if (orientation.phi > 0) {
                    tmpvec2to3.x *= -1;
                }
                tmpvec3.z -= tmpvec2to3.x * factor;
                break;
            case 'north':
            case 'south':
                tmpvec3.y += tmpvec2to3.x * factor;
                break;
            case 'east':
            case 'west':
                tmpvec3.x -= tmpvec2to3.x * factor;
                break;
            default:
            /* do nothing */
        }

        const { x, y, z } = tmpvec3;
        this.controls._do(() => this.controls.cameraControls.moveTo(x, y, z));
        this.controls.setInteractionPoint(tmpvec3);

        this._lastDragPosition.set(pointer.clientX, pointer.clientY);
    }

    /**
     * Event handler to end dragging in Dolly mode
     */
    onMouseUpEndDraggingForward() {
        if (!this._startedDragging) return;
        this._startedDragging = false;
        for (const i of this.domElement.getElementsByClassName('compass_direction')) {
            i.classList.remove('active');
        }
        if (!this._isDragging) {
            this.controls.lookFromSide(this._currentDraggingDirection, true);
        } else {
            this.controls.cameraControls.dispatchEvent({ type: 'controlend' });
        }
    }

    calculateCurrentOrientation(): { theta: number; phi: number } {
        const q = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), this.instance.view.camera.up);
        q.invert();
        // compute r
        const r = this.instance.view.camera.quaternion.clone().premultiply(q);
        // tranform it to euler
        const e = new Euler(0, 0, 0, 'YXZ').setFromQuaternion(r);

        return {
            theta: MathUtils.radToDeg(e.x),
            phi: MathUtils.radToDeg(e.y),
        };
    }

    dispose() {
        this.domElement.removeEventListener('mousemove', this.onMouseMoveDraggingForward);
        this.domElement.removeEventListener('mouseup', this.onMouseUpEndDraggingForward);

        this.instance.removeEventListener('before-camera-update', this._updateCompass);

        this.domElement.remove();
    }
}

export default Compass;
