import * as THREE from 'three';
import Extent from '@giro3d/giro3d/core/geographic/Extent';
import { Dispatch, RootState } from 'store';
import { DatasetId, SourceFileId, fromBox3 } from 'types/common';
import Dataset from 'types/Dataset';
import SeismicPlane2dLayer from 'giro3d_extensions/layers/seismic/SeismicPlane2dLayer';
import { HostView } from 'giro3d_extensions/layers/Layer';
import { useServiceContainer } from 'ServiceContainer';
import { hasSeismicPlane } from 'types/LayerState';
import { SourceFile } from 'types/SourceFile';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import SeismicPlane2dBuilder from '../giro3d_extensions/layerBuilders/SeismicPlane2dBuilder';
import BaseGiro3dService, { PickedPoint } from './BaseGiro3dService';
import { LAYER_TYPES } from './Constants';
import SeismicControls from './SeismicControls';
import LayerManager from '../giro3d_extensions/LayerManager';

import * as giro3dSlice from '../redux/giro3d';
import * as layersSlice from '../redux/layers';
import * as seismicViewSlice from '../redux/seismicView';
import ViewManager from './ViewManager';
import giro3dService from './Giro3dService';

const DEFAULT_VIEW_PADDING = 30; // px

class SeismicService extends BaseGiro3dService implements ViewManager {
    private _activeLayer: DatasetId;

    constructor() {
        super({ hostView: HostView.SeismicView, canHover: false });
        this._inspectorTitle = 'Seismic Inspector';

        const container = useServiceContainer();
        container.register('SeismicViewManager', this);
    }

    init(domElem: HTMLDivElement, inspectorDomElem: HTMLDivElement, extent: Extent, _dispatch: Dispatch) {
        super.init(domElem, inspectorDomElem, extent, _dispatch, {
            camera: new THREE.OrthographicCamera(-50, 50, 50, -50, 1, 2),
        });
        this._dispatch(giro3dSlice.setSeismicViewInitialized(true));
    }

    deinit() {
        super.deinit();
        this._dispatch(giro3dSlice.setSeismicViewInitialized(false));
    }

    protected initLayerManager() {
        return new LayerManager({
            instance: this._instance,
            segments: this._segments,
            hillshading: this._enableHillshading,
            hillshadingIntensity: this._hillshadeIntensity,
            azimuth: this._lightDirection.azimuth,
            zenith: this._lightDirection.zenith,
        });
    }

    protected initControls() {
        return new SeismicControls(this._instance, this.getIntersectionsAt.bind(this), this.getBoundingBox.bind(this));
    }

    protected onInteractionEnd() {
        if (this._interactionTimer !== null) {
            // There was already an end of interaction pending, cancel it
            clearTimeout(this._interactionTimer);
        }
        this._interactionTimer = setTimeout(this.doOnInteractionEnd.bind(this), 500);
    }

    async loadDataset(dataset: Dataset, sourceFiles: SourceFile[]) {
        if (!this._instance) return Promise.reject(new Error('Giro3d not initialized yet'));

        // Get the old camera location if we are using it to set the new camera
        let lastCameraPosition =
            this._stateObserver.select(seismicViewSlice.getSnapPosition) &&
            this._layers.get(this._activeLayer) &&
            this._currentCameraPosition
                ? (this._layers.get(this._activeLayer)[0] as SeismicPlane2dLayer).lookupCoordinate({
                      point: this._currentCameraPosition,
                      picked: false,
                  }).point
                : null;
        const lastCameraZoom =
            this._stateObserver.select(seismicViewSlice.getSnapPosition) &&
            this._layers.get(this._activeLayer) &&
            this._instance.view.camera.zoom;

        const isFlat = !this._stateObserver.select(seismicViewSlice.getShowDepthChange);

        if (Number.isNaN(lastCameraPosition?.x)) lastCameraPosition = null;

        // Remove existing layer(s)
        this.getAllLayers().forEach((layer) => this.removeLayer(layer.datasetId));

        if (dataset.type !== LAYER_TYPES.SEISMIC)
            return Promise.reject(new Error(`Layer must be of type ${LAYER_TYPES.SEISMIC}, is ${dataset.type}`));

        this._activeLayer = dataset.id;

        const layerState = this._stateObserver.select(layersSlice.get(dataset.id));

        if (!hasSeismicPlane(layerState)) throw new Error('Layer does not have seismic plane');

        const builder = new SeismicPlane2dBuilder(
            dataset,
            sourceFiles.filter((s) => s.id === this._stateObserver.select(layersSlice.getActiveSourceFile(layerState))),
            {
                dispatch: this._dispatch,
                instance: this._instance,
                layerManager: this._layerManager,
                hostView: this._hostView,
            }
        );

        builder.setFlat(isFlat);

        return builder
            .build()
            .then(async (layers) => {
                const promises: Promise<SeismicPlane2dLayer>[] = [];

                const list: SeismicPlane2dLayer[] = [];
                this._layers.set(dataset.id, list);

                for (const l of layers) {
                    list.push(l);

                    let initPromise: Promise<SeismicPlane2dLayer>;

                    if (!l.initialized) {
                        initPromise = l.init().then(() => this._instance.add(l.get3dElement()).then(() => l));
                    } else {
                        initPromise = this._instance.add(l.get3dElement()).then(() => l);
                    }

                    promises.push(initPromise);
                }

                await Promise.all(promises);

                return layers;
            })
            .then((layers) => {
                // If the layer cannot be loaded we cannot set the camera for it, skip this step
                if (layers.length === 0) return layers;

                layers[0].setZScale(this._zScale);
                this.updateBbox(this._stateObserver.select(layersSlice.getActiveSourceFile(layerState)));
                if (lastCameraPosition && !Number.isNaN(lastCameraPosition.x)) {
                    const newPosition = (layers[0] as SeismicPlane2dLayer).closestPoint(
                        new THREE.Vector3(lastCameraPosition.x, lastCameraPosition.y, lastCameraPosition.z)
                    );
                    const reversed = this._stateObserver.select(seismicViewSlice.isReversed);

                    this._controls.lookAt(
                        new THREE.Vector3(newPosition.x, reversed ? 10 : -10, newPosition.z),
                        new THREE.Vector3(newPosition.x, 0, newPosition.z),
                        false
                    );

                    if (this._currentCameraPosition)
                        this._currentCameraPosition.set(newPosition.x, reversed ? 10 : -10, newPosition.z);
                    else
                        this._currentCameraPosition = new THREE.Vector3(
                            newPosition.x,
                            reversed ? 10 : -10,
                            newPosition.z
                        );

                    this._controls.cameraControls.zoomTo(lastCameraZoom);
                }
                return layers;
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject(new Error(`Something went wrong during loading of dataset ${dataset.name}`));
            });
    }

    updateBbox(sourceFile: SourceFileId, zScaleChange?: number) {
        const layer = this._layers
            .get(this._activeLayer)
            .find((l) => l.sourceFileId === sourceFile) as SeismicPlane2dLayer;

        const bbox = layer.getBoundingBox().clone();
        bbox.min.z *= this.getZScale();

        if (zScaleChange) {
            this._controls.lookAt(
                new THREE.Vector3(
                    this._instance.view.camera.position.x,
                    this._instance.view.camera.position.y,
                    this._instance.view.camera.position.z * zScaleChange
                ),
                new THREE.Vector3(
                    this._instance.view.camera.position.x,
                    0,
                    this._instance.view.camera.position.z * zScaleChange
                ),
                false
            );
            this.onInteractionEnd();
        } else {
            const center = new THREE.Vector3();
            bbox.getCenter(center);

            this._controls.lookAt(
                new THREE.Vector3(
                    center.x,
                    this._stateObserver.select(seismicViewSlice.isReversed) ? 10 : -10,
                    center.z
                ),
                new THREE.Vector3(center.x, 0, center.z),
                false
            );

            // Snap the zoom to the top/bottom if the bbox is taller than it is wide
            // Accounts for the 'valid' area being 32px narrower and shorter than the viewport
            const viewportWidth = this._instance.viewport.offsetWidth;
            const viewportHeight = this._instance.viewport.offsetHeight;
            const datasetWidth = bbox.max.x;
            const datasetHeight = -bbox.min.z;

            const viewWidthRatio = (viewportWidth - DEFAULT_VIEW_PADDING) / viewportWidth;
            const viewHeightRatio = (viewportHeight - DEFAULT_VIEW_PADDING) / viewportHeight;

            const aspectRatioCondition =
                datasetHeight * ((viewportWidth - DEFAULT_VIEW_PADDING) / (viewportHeight - DEFAULT_VIEW_PADDING)) >
                datasetWidth;

            this._controls.cameraControls.zoomTo(
                100 /
                    (aspectRatioCondition
                        ? (datasetHeight * (viewportWidth / viewportHeight)) / viewHeightRatio
                        : datasetWidth / viewWidthRatio),
                false
            );

            if (this._currentCameraPosition)
                this._currentCameraPosition.set(
                    center.x,
                    this._stateObserver.select(seismicViewSlice.isReversed) ? 10 : -10,
                    center.z
                );
            else
                this._currentCameraPosition = new THREE.Vector3(
                    center.x,
                    this._stateObserver.select(seismicViewSlice.isReversed) ? 10 : -10,
                    center.z
                );
        }

        this._controls.cameraControls.setBoundary(bbox);
        if (!this._stateObserver.select(giro3dSlice.getSeismicVolume)?.equals(bbox))
            this._dispatch(giro3dSlice.setSeismicVolume(fromBox3(bbox)));
    }

    removeLayer(datasetOrId) {
        let id = datasetOrId;
        if (typeof datasetOrId === 'object') id = datasetOrId.id; // assume id

        if (id === this._activeLayer) this._activeLayer = null;

        super.removeLayer(datasetOrId);
    }

    updateCoordinates(point: PickedPoint = null) {
        if (point?.picked) {
            const layerState = this._stateObserver.select(layersSlice.get(this._activeLayer));
            if (!hasSeismicPlane(layerState)) throw new Error('Layer does not have seismic plane');

            const layer = this.getLayersForDataset<SeismicPlane2dLayer>(this._activeLayer).find(
                (l) => l.sourceFileId === layerState.activeFile
            );

            if (layer) {
                const newPoint = layer.lookupCoordinate(point);
                return giro3dService.updateCoordinates(newPoint, true);
            }
        }
        return giro3dService.updateCoordinates();
    }

    private reverseCameraSide(reversed: boolean) {
        const position = this._controls.getPosition(this._tmpVec3);
        position.y = reversed ? 10 : -10;
        this._controls.cameraControls.setPosition(position.x, reversed ? 10 : -10, position.z);
        this._controls.cameraControls.dispatchEvent({ type: 'update' });
        if (this._currentCameraPosition) this._currentCameraPosition.set(position.x, reversed ? 10 : -10, position.z);
        else this._currentCameraPosition = new THREE.Vector3(position.x, reversed ? 10 : -10, position.z);
    }

    protected setZScale(scale: number): void {
        if (!this._instance) {
            return;
        }
        if (!this._controls.cameraControls.enabled) {
            return;
        }
        const oldScale = this._zScale;
        this._zScale = scale;
        if (oldScale === scale) {
            return;
        }

        // Scale the seg-y so its the correct height
        this._layers.get(this._activeLayer)[0].setZScale(scale);

        const layerState = this._stateObserver.select(layersSlice.get(this._activeLayer));
        if (!hasSeismicPlane(layerState)) throw new Error('Layer does not have seismic plane');
        this.updateBbox(this._stateObserver.select(layersSlice.getActiveSourceFile(layerState)), scale / oldScale);
        this.onInteractionEnd();
    }

    protected onStateObserverCreated(observer: StateObserver<RootState>): void {
        super.onStateObserverCreated(observer);

        observer.subscribe(seismicViewSlice.isReversed, (v) => this.reverseCameraSide(v));
        observer.subscribe(seismicViewSlice.getZScale, (v) => this.setZScale(v));
    }
}

export default SeismicService;
