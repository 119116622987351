import * as THREE from 'three';
import {
    ColoringMode,
    DataPointMode,
    HasAttributes,
    HasBorehole,
    HasColoringMode,
    HasDataPointMode,
    HasOpacity,
    HasOverlayColor,
    HasRadius,
    LayerState,
} from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import { DEFAULT_CABLE_PIPELINE_SETTINGS } from '../../../services/Constants';
import LineLayer, { ConstructorParams, Settings } from './LineLayer';
import LayerStateObserver from '../LayerStateObserver';
import AnodeCollection from './anodes/AnodeCollection';

type PipelineLayerState = LayerState &
    HasOverlayColor &
    HasOpacity &
    HasBorehole &
    HasAttributes &
    HasRadius &
    HasColoringMode &
    HasDataPointMode;

export default class PipelineLayer extends LineLayer<Settings, PipelineLayerState> {
    readonly isPipelineLayer = true as const;
    private _anodeRoot: THREE.Group;

    constructor(params: ConstructorParams) {
        super(params);

        this.type = 'PipelineLayer';

        this.settings.overlayColor = new THREE.Color(DEFAULT_CABLE_PIPELINE_SETTINGS.OVERLAY_COLOR);

        this.settings.radius = DEFAULT_CABLE_PIPELINE_SETTINGS.RADIUS;
    }

    async initOnce() {
        await super.initOnce();
        if (!this._anodeRoot) {
            this._anodeRoot = new THREE.Group();
            this._anodeRoot.name = 'Anodes';
        }

        this.object3d.add(this._anodeRoot);
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<PipelineLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getCurrentDataPointMode(this.layerState), (v) => this.updateDataPoints(v));
    }

    private deleteDataPointObjects() {
        this.traverseAnodeCollections((coll) => coll.dispose());
        this._anodeRoot?.clear();
        this.notifyLayerChange();
    }

    private computeAnodeRadius() {
        return this.settings.radius * 1.3;
    }

    private async createAnodes() {
        const activeAttribute = this._layerObserver.select(layersSlice.getActiveAttribute(this.layerState));
        if (!activeAttribute) {
            return;
        }
        const geometries = await this._geometrySource.getGeometries();
        const properties = await this._geometrySource.getPropertyValues();

        const anodeRadius = this.computeAnodeRadius();

        const { colors, min, max } = this._colorMapProperties;

        this.traverseAnodeCollections((coll) => coll.dispose());

        this._anodeRoot.clear();

        for (let i = 0; i < geometries.length; i++) {
            const geometry = geometries[i];
            const dataPoints = new Map<string, Float32Array>();
            for (const [k, v] of properties) {
                dataPoints.set(k, v[i]);
            }

            const anodeCollection = new AnodeCollection(DEFAULT_CABLE_PIPELINE_SETTINGS.RADIAL_SEGMENTS);

            anodeCollection.update({
                attributeName: activeAttribute.name,
                min,
                max,
                coordinates: geometry.points,
                dataPoints,
                radius: anodeRadius,
                lut: colors,
            });

            anodeCollection.position.copy(geometry.origin);

            this._anodeRoot.add(anodeCollection);
        }

        this.object3d.updateMatrixWorld(true);

        this.notifyLayerChange();
    }

    protected override setRadius(radius: number): void {
        super.setRadius(radius);
        const anodeRadius = this.computeAnodeRadius();
        this.traverseAnodeCollections((coll) => coll.setRadius(anodeRadius));
    }

    override async doSetVisibility(visible: boolean): Promise<void> {
        await super.doSetVisibility(visible);
        this.traverseAnodeCollections((coll) => coll.setVisibility(visible));
    }

    protected override applyOverlay(): void {
        super.applyOverlay();
        this.traverseAnodeCollections((coll) => coll.dispose());
        this._anodeRoot?.clear();
    }

    protected override applyColorMap() {
        super.applyColorMap();

        const mode = this._layerObserver.select(layersSlice.getCurrentDataPointMode(this.layerState));

        if (mode !== DataPointMode.Continuous) {
            this.updateDataPoints(mode);
        } else {
            this._lineEntity?.setColorMap(this._colorMapProperties);
        }
    }

    protected override setBrightness(brightness: number): void {
        super.setBrightness(brightness);
        this.traverseAnodeCollections((coll) => coll.setBrightness(brightness));
    }

    private traverseAnodeCollections(callback: (collection: AnodeCollection) => void) {
        if (this._anodeRoot) {
            this._anodeRoot.traverse((obj) => {
                if (obj instanceof AnodeCollection) {
                    callback(obj);
                }
            });
        }
    }

    private async updateDataPoints(mode: DataPointMode) {
        switch (mode) {
            case DataPointMode.Anode:
                this.createAnodes();
                // If data point mode is not continuous, then we just set a solid
                // color to the line, to be able to see the anodes with correct contrast.
                this._lineEntity?.setColor(this.settings.overlayColor);
                break;
            default:
                this.deleteDataPointObjects();
                if (this._coloringMode === ColoringMode.Colormap) {
                    this.applyColorMap();
                }
                break;
        }
    }
}
