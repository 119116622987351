import { useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Dispatch, useAppDispatch, useAppSelector } from 'store';
import { SerializedState, StoryMap } from 'types/serialization/View';
import ApiErrors from 'services/ApiErrors';
import * as datasetsSlice from 'redux/datasets';
import { deleteStoryMap, deleteView, fetchStories, fetchViews } from 'redux/actions';
import * as giro3dSlice from 'redux/giro3d';
import { useMountEffect } from 'components/utils';
import { getUsernames } from 'redux/selectors';
import { createDateReadout, getUsername } from 'services/MentionFormatting';
import { User } from 'types/User';
import InlineDropMenu from '../../InlineDropMenu';

import { CreateView, EditView, ViewItem } from './View';
import { CreateStory, StoryItem } from './StoryMap';

export const ViewActions = ({ view, project, doLoadView, doEditView, doDeleteView, doCopyLink }) => (
    <InlineDropMenu>
        <Button className="borderless light-blue" title="Open View" onClick={doLoadView}>
            <i className="fas fa-eye" />
        </Button>
        <Button className="borderless light-blue" title="Copy View Link" onClick={doCopyLink}>
            <i className="fas fa-link" />
        </Button>
        {project.user_permissions.update_project ? (
            <>
                <Button className="borderless green" title="Edit View" onClick={doEditView}>
                    <i className="fas fa-pen" />
                </Button>
                {project.default_view_id !== view.id ? (
                    <Button className="borderless red" title="Delete View" onClick={doDeleteView}>
                        <i className="fas fa-trash-can" />
                    </Button>
                ) : null}
            </>
        ) : null}
    </InlineDropMenu>
);

enum MODAL {
    PROMPT = 'prompt',
    PROCESSING = 'processing',
    CONFIRMATION = 'confirmation',
    ERROR = 'error',
}

const deleteViewModalContent = (
    deletingView: { view: SerializedState; state: MODAL; errorText: string },
    dispatch: Dispatch,
    setDeleting
) => {
    switch (deletingView.state) {
        case MODAL.PROMPT:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                        <span className="big-modal-text">Are you sure?</span>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => {
                                setDeleting({ ...deletingView, state: MODAL.PROCESSING });
                                dispatch(deleteView(deletingView.view))
                                    .then(() => {
                                        setDeleting({ ...deletingView, state: MODAL.CONFIRMATION });
                                        setTimeout(() => setDeleting(undefined), 2000);
                                    })
                                    .catch((err) =>
                                        setDeleting({
                                            ...deletingView,
                                            state: MODAL.ERROR,
                                            errorText: ApiErrors.getErrorMessage(err),
                                        })
                                    );
                            }}
                        >
                            Yes, Delete this View
                        </button>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="pane-button large" onClick={() => setDeleting(undefined)}>
                            Cancel
                        </button>
                    </ModalFooter>
                </>
            );
        case MODAL.PROCESSING:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                        <span className="big-modal-text">Deleting View...</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.CONFIRMATION:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                        <span className="big-modal-text">View deleted</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.ERROR:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                        <span className="big-modal-text">An error occured</span>
                        <span className="small-modal-text">{deletingView.errorText}</span>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => setDeleting(undefined)}
                        >
                            OK
                        </button>
                    </ModalFooter>
                </>
            );
        default:
            return null;
    }
};

const deleteStoryModalContent = (
    deletingStory: { story: StoryMap; state: MODAL; errorText: string },
    dispatch: Dispatch,
    setDeleting
) => {
    switch (deletingStory.state) {
        case MODAL.PROMPT:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                        <span className="big-modal-text">Are you sure?</span>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => {
                                setDeleting({ ...deletingStory, state: MODAL.PROCESSING });
                                dispatch(deleteStoryMap(deletingStory.story))
                                    .then(() => {
                                        setDeleting({ ...deletingStory, state: MODAL.CONFIRMATION });
                                        setTimeout(() => setDeleting(undefined), 2000);
                                    })
                                    .catch((err) =>
                                        setDeleting({
                                            ...deletingStory,
                                            state: MODAL.ERROR,
                                            errorText: ApiErrors.getErrorMessage(err),
                                        })
                                    );
                            }}
                        >
                            Yes, Delete this Story
                        </button>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="pane-button large" onClick={() => setDeleting(undefined)}>
                            Cancel
                        </button>
                    </ModalFooter>
                </>
            );
        case MODAL.PROCESSING:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                        <span className="big-modal-text">Deleting Story...</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.CONFIRMATION:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                        <span className="big-modal-text">Story deleted</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.ERROR:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                        <span className="big-modal-text">An error occured</span>
                        <span className="small-modal-text">{deletingStory.errorText}</span>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => setDeleting(undefined)}
                        >
                            OK
                        </button>
                    </ModalFooter>
                </>
            );
        default:
            return null;
    }
};

const ViewMenu = () => {
    const dispatch = useAppDispatch();

    const activeView = useAppSelector(giro3dSlice.getLoadedView);
    const activeStory = useAppSelector(giro3dSlice.getActiveStory);
    const [creatingStory, setCreatingStory] = useState(false);
    const [creatingView, setCreatingView] = useState(false);
    const [editingView, setEditingView] = useState<SerializedState>();
    const [deletingStory, setDeletingStory] = useState<{ story: StoryMap; state: MODAL; errorText: string }>();
    const [deletingView, setDeletingView] = useState<{ view: SerializedState; state: MODAL; errorText: string }>();
    const [copyModal, setCopyModal] = useState(false);

    const project = useAppSelector(datasetsSlice.currentProject);

    const views = useAppSelector(giro3dSlice.getViews);
    const stories = useAppSelector(giro3dSlice.getStories);
    const users: User[] = useAppSelector(getUsernames);

    const sortStoriesViews = (
        unsortedViews: SerializedState[],
        unsortedStories: StoryMap[]
    ): (SerializedState | StoryMap)[] => {
        const combined = [
            ...(unsortedViews?.map((v) => ({ ...v, sort_date: new Date(v.updated_at).valueOf() })) ?? []),
            ...(unsortedStories?.map((s) => ({ ...s, sort_date: new Date(s.updated_at).valueOf() })) ?? []),
        ];

        return combined.sort((a, b) => {
            if (a.id === project.default_view_id) return -1;
            if (b.id === project.default_view_id) return 1;
            return b.sort_date - a.sort_date;
        });
    };

    useMountEffect(() => {
        if (views === undefined) dispatch(fetchViews(project.id));
        if (stories === undefined) dispatch(fetchStories(project.id));
    });

    if (creatingStory) return <CreateStory project={project} setCreating={setCreatingStory} />;
    if (creatingView) return <CreateView project={project} setCopyModal={setCopyModal} setCreating={setCreatingView} />;
    if (editingView) return <EditView view={editingView} project={project} setEditing={setEditingView} />;

    const view = views?.find((v) => v.id === activeView);

    return (
        <>
            <Modal isOpen={deletingView !== undefined} keyboard={false} centered className="modal-confirm">
                {deletingView !== undefined ? deleteViewModalContent(deletingView, dispatch, setDeletingView) : null}
            </Modal>
            <Modal isOpen={deletingStory !== undefined} keyboard={false} centered className="modal-confirm">
                {deletingStory !== undefined
                    ? deleteStoryModalContent(deletingStory, dispatch, setDeletingStory)
                    : null}
            </Modal>
            <Modal isOpen={copyModal} keyboard={false} centered className="modal-confirm">
                <ModalHeader />
                <ModalBody>
                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                    <span className="big-modal-text">Link copied</span>
                </ModalBody>
                <ModalFooter />
            </Modal>
            <div className="tabContent">
                <div className="input-row">
                    <div className="grow" />
                    <button
                        type="button"
                        className="pane-button highlight"
                        title="Create View"
                        onClick={() => setCreatingView(true)}
                    >
                        <i className="fas fa-video-plus" />
                    </button>
                    <button
                        type="button"
                        className="pane-button highlight"
                        title="Create Story"
                        onClick={() => setCreatingStory(true)}
                    >
                        <i className="fas fa-rectangle-history-circle-plus" />
                    </button>
                </div>
                {view ? (
                    <div className="description">
                        <span className="title">{view.name}</span>
                        <span className="author-line">
                            {getUsername(view.created_by_id, users)} {createDateReadout(view.created_at)} ago
                        </span>
                        {view.description}
                        <span className="author-line">
                            Updated by {getUsername(view.updated_by_id, users)} {createDateReadout(view.updated_at)} ago
                        </span>
                    </div>
                ) : null}

                <ul className="list">
                    {sortStoriesViews(views, stories).map((viewOrStory) =>
                        Object.prototype.hasOwnProperty.call(viewOrStory, 'view_type') ? (
                            <ViewItem
                                key={viewOrStory.id}
                                view={viewOrStory as SerializedState}
                                project={project}
                                setEditing={setEditingView}
                                setDeleting={() =>
                                    setDeletingView({
                                        view: viewOrStory as SerializedState,
                                        state: MODAL.PROMPT,
                                        errorText: '',
                                    })
                                }
                                setCopyModal={setCopyModal}
                                highlight={activeView === viewOrStory.id}
                            />
                        ) : (
                            <StoryItem
                                key={viewOrStory.id}
                                storyMap={viewOrStory as StoryMap}
                                project={project}
                                setDeleting={() =>
                                    setDeletingStory({
                                        story: viewOrStory as StoryMap,
                                        state: MODAL.PROMPT,
                                        errorText: '',
                                    })
                                }
                                setCopyModal={setCopyModal}
                                highlight={activeStory === viewOrStory.id}
                            />
                        )
                    )}
                </ul>
            </div>
        </>
    );
};

export default ViewMenu;
