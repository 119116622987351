import { nanoid } from '@reduxjs/toolkit';

export type Props = {
    value: number;
    min?: number;
    max?: number;
    step: number;
    title: string;
    icon?: string;
    unit?: string;
    onChange: (v: number) => void;
    onBlur?: (v: number) => void;
    arrows?: boolean;
    disabled?: boolean;
    iconRotation?: number;
    allowUndefined?: boolean;
};

function Input(props: Props) {
    const id = nanoid();

    function emit(v: number) {
        if (Number.isNaN(v)) props.onChange(props.allowUndefined ? undefined : props.value);
        else props.onChange(v);
    }
    function emitBlur(v: number) {
        if (Number.isNaN(v)) props.onBlur(props.allowUndefined ? undefined : props.value);
        else props.onBlur(v);
    }

    return (
        <li>
            <label htmlFor={id} className="cursor">
                <div>
                    <i className={props.icon} style={{ rotate: `${props.iconRotation?.toString()}deg` }} />
                </div>
                <div>
                    <button type="button">{props.title}</button>
                </div>
                <div>
                    <input
                        id={id}
                        type="number"
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        onChange={(e) => emit(e.target.valueAsNumber)}
                        onBlur={(e) => {
                            if (props.onBlur) emitBlur(e.currentTarget.valueAsNumber);
                        }}
                        disabled={props.disabled}
                    />
                    <span>{props.unit}</span>
                    {props.arrows ? (
                        <div className="incrementers">
                            <button type="button" onClick={() => emit(props.value + props.step)}>
                                <i className="fas fa-caret-up" />
                            </button>
                            <button type="button" onClick={() => emit(props.value - props.step)}>
                                <i className="fas fa-caret-down" />
                            </button>
                        </div>
                    ) : null}
                </div>
            </label>
        </li>
    );
}

export default Input;
