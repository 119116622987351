import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import Select from 'components/forms/Select';
import BaseInput from 'components/forms/BaseInput';
import { updateProject, fetchProjections, fetchOrganizations } from '../../redux/actions';
import { getOrganizations, getProjections } from '../../redux/selectors';
import ApiErrors from '../../services/ApiErrors';
import handleApiError from '../../services/Forms';

const EditProject = (props) => {
    const dispatch = useDispatch();

    const { project, endEdit } = props;

    const organizations = useSelector(getOrganizations);
    const projections = useSelector(getProjections);

    const STATE = {
        PROCESSING: 'processing',
        COMPLETE: 'complete',
        ERROR: 'error',
    };
    const [modalState, setModal] = useState(null);
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        dispatch(fetchProjections());
        dispatch(fetchOrganizations());
    }, []);

    if (!organizations || !projections) return <Spinner animation="border" />;

    const modalContent = () => {
        switch (modalState) {
            case STATE.PROCESSING:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Editing project</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case STATE.COMPLETE:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Project edited</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case STATE.ERROR:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setModal(null)}>Okay</Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) errors.title = 'Required';
        if (!values.organization) errors.organization = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const onSubmit = async (values, helpers) => {
        setModal(STATE.PROCESSING);
        dispatch(
            updateProject({
                id: values.id,
                name: values.title,
                description: values.description,
                organization_id: values.organization.value,
                projection: values.projection.value,
                default_view_id: project.default_view_id, // Holdover from when it was possible to set a view here
            })
        )
            .catch((err) => {
                handleApiError(err, helpers);
                setErrorText(ApiErrors.getErrorMessage(err));
                setModal(STATE.ERROR);
            })
            .then(() => {
                setModal(STATE.COMPLETE);
                setTimeout(endEdit, 2000);
            });
    };

    return (
        <>
            <ModalHeader>Edit Project</ModalHeader>
            <Formik
                enableReinitialize
                initialValues={{
                    id: project.id,
                    title: project.name,
                    description: project.description,
                    organization: {
                        value: project.organization_id,
                        label: organizations.find((o) => o.id === project.organization_id).display_name,
                    },
                    projection: {
                        value: project.projection,
                        label: projections.find((p) => p.srid === project.projection.toString()).title,
                    },
                }}
                onSubmit={onSubmit}
                validate={validate}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <BaseInput title="Project Name" name="title" label="Project Name" titled />
                            <BaseInput title="Description" name="description" label="Description" long titled />
                            <hr />
                            <Select
                                title="Organization"
                                name="organization"
                                placeholder="Organization"
                                value={values.organization}
                                options={organizations.map((e) => ({ label: e.display_name, value: e.id }))}
                                setFieldValue={setFieldValue}
                            />
                            <Select
                                title="Projection"
                                name="projection"
                                placeholder="Projection"
                                value={values.projection}
                                options={projections?.map((e) => ({ label: e.title, value: e.srid }))}
                                isSearchable
                                setFieldValue={setFieldValue}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large" id="cancel-update" onClick={endEdit}>
                                Abort
                            </button>
                            <button
                                type="submit"
                                className="pane-button large highlight"
                                id="complete-update"
                                disabled={isSubmitting}
                            >
                                Save
                            </button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
            <Modal isOpen={modalState !== null} className="modal-confirm">
                {modalContent()}
            </Modal>
        </>
    );
};

export default EditProject;
