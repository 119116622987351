import { CopcProperties } from 'types/Dataset';
import Layer, { Settings, LayerEventMap } from 'giro3d_extensions/layers/Layer';
import { LayerState } from 'types/LayerState';
import { SourceFile } from 'types/SourceFile';
import PointCloud from '@giro3d/giro3d/entities/PointCloud';
import COPCSource from '@giro3d/giro3d/sources/COPCSource';
import CopcLayer from 'giro3d_extensions/layers/copc/CopcLayer';
import ColorMap from '@giro3d/giro3d/core/ColorMap';
import { LAYER_DATA_TYPES } from 'services/Constants';
import { Color } from 'three';
import LayerBuilder from './LayerBuilder';

export default class CopcBuilder extends LayerBuilder<CopcProperties> {
    protected override buildLayer(sourceFile: SourceFile): Promise<Layer<Settings, LayerEventMap, LayerState>> {
        const url = this.buildUrl(sourceFile.links.download);

        const source = new COPCSource({ url });

        const entity = new PointCloud({ source });
        // Assign a new colormap as the entity uses a default colormap shared between all entities
        // https://gitlab.com/giro3d/giro3d/-/issues/560
        entity.colorMap = new ColorMap({ colors: [new Color('white')], min: 0, max: 1 });

        entity.userData.datasetId = this.getDatasetId();
        entity.userData.sourceFileId = sourceFile.id;

        let defaultAttribute: string;

        switch (this.getDatasetDatatype()) {
            case LAYER_DATA_TYPES.ELEVATIONCOPC:
                defaultAttribute = 'Z';
                break;
            case LAYER_DATA_TYPES.INTENSITYCOPC:
                defaultAttribute = 'Intensity';
                break;
            case LAYER_DATA_TYPES.RGBCOPC:
                defaultAttribute = 'Color';
                break;
            default:
                defaultAttribute = 'Color';
                break;
        }

        const result = new CopcLayer({
            instance: this.getInstance(),
            layerManager: this._layerManager,
            datasetId: this.getDatasetId(),
            readableName: this.getDatasetName(),
            datasetType: this.getDatasetType(),
            sourceFileId: sourceFile.id,
            dispatch: this._dispatch,
            defaultAttribute,
            entity,
            getFootprint: () => sourceFile.geometry,
            hostView: this._hostView,
        });

        return Promise.resolve(result);
    }
}
