import { useState } from 'react';

import { DatasetId } from 'types/common';
import { useAppSelector } from 'store';

import InlineDropMenu from 'components/InlineDropMenu';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { LayerState } from 'types/LayerState';
import { useDroppable } from '@dnd-kit/core';
import DatasetListItem from './DatasetListItem';
import * as layersSlice from '../../../redux/layers';
import * as giro3dSlice from '../../../redux/giro3d';

export type Props = {
    datasetIds: DatasetId[];
    groupName: string;
    groupId: string;
    customGroup: boolean;
    setSettingsModal: () => void;
    setDeleteModal: () => void;
    highlight: boolean;
};

const DatasetListGroup = (props: Props) => {
    const dispatch = useDispatch();

    const { datasetIds, groupName, groupId, customGroup, setSettingsModal, setDeleteModal, highlight } = props;

    const { setNodeRef, isOver } = useDroppable({ id: groupId });

    const layerStates: LayerState[] = useAppSelector(layersSlice.all);
    const clickedDatasetId = useAppSelector(giro3dSlice.getSelection)?.datasetId;

    const [open, setOpen] = useState(false);

    const hideOthers = () =>
        layerStates.forEach((layerState) => {
            if (!datasetIds.includes(layerState.datasetId))
                dispatch(layersSlice.setVisibility({ layer: layerState, value: false }));
        });
    const hideGroup = () =>
        layerStates.forEach((layerState) => {
            if (datasetIds.includes(layerState.datasetId))
                dispatch(layersSlice.setVisibility({ layer: layerState, value: false }));
        });
    const showGroup = () =>
        layerStates.forEach((layerState) => {
            if (datasetIds.includes(layerState.datasetId))
                dispatch(layersSlice.setVisibility({ layer: layerState, value: true }));
        });

    return (
        <li
            className={`${highlight || (customGroup && isOver) ? 'hovered' : ''} ${!open && datasetIds.includes(clickedDatasetId) ? 'highlight' : ''}`}
            id={groupId}
            ref={setNodeRef}
        >
            <div className="dropdown">
                <label htmlFor={`${groupId}-dropdown`} className={`${customGroup ? 'custom' : ''}`}>
                    <span>{groupName}</span>
                    <input type="checkbox" id={`${groupId}-dropdown`} onChange={(e) => setOpen(e.target.checked)} />
                </label>
                <InlineDropMenu>
                    {customGroup ? (
                        <>
                            <Button className="borderless red" title="Delete group" onClick={setDeleteModal}>
                                <i className="fas fa-trash-can" />
                            </Button>
                            <Button className="borderless yellow" title="Edit group" onClick={setSettingsModal}>
                                <i className="fas fa-gear" />
                            </Button>
                        </>
                    ) : null}
                    <Button className="borderless light-blue full-color" title="Hide all others" onClick={hideOthers}>
                        <i className="fas fa-circle" />
                    </Button>
                    <hr className="centre-drop-menu-divider" />
                    <Button className="borderless red full-color" title="Hide group" onClick={hideGroup}>
                        <i className="fas fa-eye-slash" />
                    </Button>
                    <hr className="centre-drop-menu-divider" />
                    <Button className="borderless green full-color" title="Show group" onClick={showGroup}>
                        <i className="fas fa-eye" />
                    </Button>
                </InlineDropMenu>
            </div>
            <ul>
                {open
                    ? datasetIds.map((datasetId) => (
                          <DatasetListItem
                              key={datasetId}
                              id={`groupable-${datasetId}`}
                              datasetId={datasetId}
                              groupOpen={open}
                              handleMode="group"
                          />
                      ))
                    : null}
            </ul>
        </li>
    );
};

export default DatasetListGroup;
