import { useAppDispatch, useAppSelector } from 'store';
import Dataset from 'types/Dataset';
import * as settingsSlice from 'redux/settings';
import ToggleSwitch from 'components/ToggleSwitch';
import { Feature } from 'ol';
import * as giro3dSlice from '../../../redux/giro3d';
import * as datasetsSlice from '../../../redux/datasets';

const FeatureReadout = (props: { feature: Feature; dataset: Dataset }) => {
    const dispatch = useAppDispatch();
    const showEmpty = useAppSelector(settingsSlice.getShowEmptyVectorRows);

    const properties = Object.entries(props.feature.getProperties()).filter(
        ([key]) => key !== 'geometry' && key !== 'geometryProperty'
    );

    const elements = [];

    properties.forEach(([key, value]) => {
        if (showEmpty || value)
            elements.push(
                <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                </tr>
            );
    });

    return (
        <>
            <div className="inspector-title">
                <span>Vector - {props.dataset.name}</span>
            </div>
            <div className="input-row">
                <ToggleSwitch
                    id="showEmpty"
                    checked={showEmpty}
                    onChange={(v) => dispatch(settingsSlice.showEmptyVectorRows(v.target.checked))}
                />
                Show Empty Rows
            </div>
            <div className="scrollable">
                <table className="firstColumn">
                    <tbody>{elements}</tbody>
                </table>
            </div>
        </>
    );
};

const FeatureInfo = () => {
    const selection = useAppSelector(giro3dSlice.getSelection);
    const feature = selection.feature;

    const dataset = useAppSelector(datasetsSlice.get(selection.datasetId));

    if (!feature) return null;

    return (
        <div className="tabContent">
            <FeatureReadout feature={feature} dataset={dataset} />
        </div>
    );
};

export default FeatureInfo;
