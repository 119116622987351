/* eslint-disable react/no-unused-prop-types */
import { nanoid } from '@reduxjs/toolkit';

export type Format = 'raw' | 'percent' | 'degrees';

export type Props = {
    value: number;
    format?: Format;
    min: number;
    max: number;
    step: number;
    onChange: (value: number) => void;
    title?: string;
};

export type TitledProps = Props & { title: string };

function format(value: number, type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return Math.round(value * 100);
        default:
            return value;
    }
}

function unformat(value: number, type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return value / 100;
        default:
            return value;
    }
}

function getSuffix(type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return '%';
        case 'degrees':
            return '°';
        default:
            return null;
    }
}

export function BareSettingSlider(props: Props) {
    function emit(v: number) {
        props.onChange(Number.isNaN(v) ? props.value : unformat(v, props.format));
    }

    const id = nanoid();

    return (
        <input
            id={`${id}-slider`}
            type="range"
            min={format(props.min, props.format)}
            max={format(props.max, props.format)}
            step={format(props.step, props.format)}
            value={format(props.value, props.format)}
            autoComplete="off"
            className="settings-slider"
            onChange={(e) => emit(e.target.valueAsNumber)}
            title={props.title}
        />
    );
}

export function SettingSliderInput(props: Props) {
    function emit(v: number) {
        props.onChange(Number.isNaN(v) ? props.value : unformat(v, props.format));
    }

    const id = nanoid();

    return (
        <table>
            <tbody>
                <tr className="settings-slider-text">
                    <td>
                        <input
                            id={`${id}-input`}
                            type="number"
                            step={format(props.step, props.format)}
                            value={format(props.value, props.format)}
                            autoComplete="off"
                            className="settings-slider"
                            onChange={(e) => emit(e.target.valueAsNumber)}
                        />
                    </td>
                    <td>{getSuffix(props.format)}</td>
                </tr>
            </tbody>
        </table>
    );
}

export function SettingSlider(props: TitledProps) {
    return (
        <li>
            <div>
                <span>{props.title}</span>
                <BareSettingSlider {...props} />
                <SettingSliderInput {...props} />
            </div>
        </li>
    );
}
