import { Color, ColorRepresentation } from 'three';
import { MODE } from '@giro3d/giro3d/renderer/PointCloudMaterial';
import { COLORMAP_NAMES } from 'types/ColorMap';
import { AnnotationFilter, AnnotationGeometryType, AnnotationInteractionState } from 'types/Annotation';

export enum PAGE {
    OVERVIEW = 'overview',
    PROJECT = 'project',
    LIBRARY = 'library',
    ADMIN = 'admin',
}

/**
 * Layer types, must match the DatasetType from the API
 */
export enum LAYER_TYPES {
    BACKSCATTER = 'backscatter',
    BATHYMETRY = 'bathymetry',
    BOREHOLE = 'borehole',
    CABLE = 'cable',
    DOCUMENT = 'document',
    PIPELINE = 'pipeline',
    TRACK = 'track',
    VESSEL = 'vessel',
    SAS = 'sas',
    SEISMIC = 'seismic',
    VECTOR = 'vector',
    ATTRIBUTE = 'attribute',
    EM = 'em',
    HORIZON = 'horizon',
    WATER_COLUMN = 'watercolumn',
    CAMERA = 'camera',
    LASER = 'laser',
    UNKNOWN = 'unknown',
}

export function isElevationType(type: LAYER_TYPES) {
    return [LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(type);
}

/**
 * Layer data types, must match the DatasetDatatype from the API
 */
export enum LAYER_DATA_TYPES {
    SINGLEBANDCOG = 'singlebandcog',
    MULTIBANDCOG = 'multibandcog',
    OBJECT3D = 'object3d',
    POINTCLOUD = 'pointcloud',
    VECTOR = 'vector',
    SEGY = 'SEG-Y',
    LAS = 'las',
    INTENSITYCOPC = 'intensitycopc',
    RGBCOPC = 'rgbcopc',
    ELEVATIONCOPC = 'elevationcopc',
    UNKNOWN = 'unknown',
}

export function isRasterType(type: LAYER_DATA_TYPES) {
    return [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG].includes(type);
}

/**
 * Layer state, must include the DatasetState from the API
 */
export enum LAYER_STATES {
    EMPTY = 'empty',
    UPLOADING = 'uploading',
    VALIDATION_FAILED = 'validation_failed',
    CONVERTING = 'converting',
    CONVERSION_FAILED = 'conversion_failed',
    ACTIVE = 'active',
    DELETING = 'deleting',
    DELETION_FAILED = 'deletion_failed',

    // Additional states for Giro3D view
    LOADING = 'loading',
    LOADING_FAILED = 'loading_failed',
    LOADED = 'loaded',
}

/**
 * SourceFile state, must include the SourceFileState from the API
 */
export enum SOURCE_FILE_STATES {
    EMPTY = 'empty',
    VALIDATING = 'validating',
    VALIDATION_FAILED = 'validation_failed',
    UPLOADING = 'uploading',
    INGESTING = 'ingesting',
    INGESTED = 'ingested',
    INGESTION_FAILED = 'ingestion_failed',
    CONVERTING = 'converting',
    CONVERSION_FAILED = 'conversion_failed',
    ACTIVE = 'active',
    DELETING = 'deleting',
    DELETION_FAILED = 'deletion_failed',

    // Additional states for Giro3D view
    LOADING = 'loading',
    LOADING_FAILED = 'loading_failed',
    LOADED = 'loaded',
}

export enum ORDER_MODE {
    FILE = 'file',
    LINE = 'line',
    NAME = 'search',
}

/**
 * Valid file extensions that the backend can process
 */
export const PROCESSABLE_EXTENSIONS = [
    'tif',
    'tiff',
    'json',
    'geojson',
    'las',
    'xyz',
    'sgy',
    'segy',
    'zip',
    'h5',
    'hdf5',
];

/**
 * Collection state for overview map (unrelated to API)
 */
export enum COLLECTION_STATES {
    LOADING = 'loading',
    LOADING_FAILED = 'loading_failed',
    LOADED = 'loaded',
}

/**
 * Project state for overview map (unrelated to API)
 */
export enum PROJECT_STATES {
    LOADING = 'loading',
    LOADING_FAILED = 'loading_failed',
    LOADED = 'loaded',
}

/**
 * Combined state of datasets loaded in giro3d
 */
export enum DATASETS_STATES {
    LOADING = 'loading',
    LOADED = 'loaded',
}

/**
 * Settings to know how to handle a layer when changing the ZScale
 */
export enum ZSCALE_BEHAVIOR {
    BY_PARENT = 'byparent', // Scale is done by the parent (e.g. layer added to a Map instance)
    SCALE = 'scale', // Scale this object (e.g. pointcloud)
    COUNTERACT = 'counteract', // Scale-back this object to not distort it - requires its position to be set (i.e. not 0,0,0)
}

/**
 * Default settings for annotation filter (also for reset)
 */
export const DEFAULT_ANNOTATION_FILTER: AnnotationFilter = {
    observation: true,
    object: true,
    point: true,
    line: true,
    polygon: true,
    authors: [],
    datasets: [],
    startDate: '',
    endDate: '',
    status: ['in_progress', 'complete'],
    ssdmTypes: [],
    unresolvedComments: false,
};

export const TICKS_PRESETS = [50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50, 10, 5, 1];

export const DEFAULT_SHAPE_SETTINGS = {
    COLOR: '#99ff99',
    NORMAL_WIDTH: 2,
    NORMAL_BORDER_WIDTH: 1,
    THICK_WIDTH: 3,
    THICK_BORDER_WIDTH: 1,
};

export type AnnotationStyle = {
    vertexRadius: Record<AnnotationGeometryType, number>;
    color: ColorRepresentation;
    width: number;
    borderWidth: number;
};

export const ANNOTATION_STYLES: Record<AnnotationInteractionState, AnnotationStyle> = {
    normal: {
        vertexRadius: {
            'Point': 6,
            'LineString': 4,
            'Polygon': 4,
        },
        color: DEFAULT_SHAPE_SETTINGS.COLOR,
        width: DEFAULT_SHAPE_SETTINGS.NORMAL_WIDTH,
        borderWidth: DEFAULT_SHAPE_SETTINGS.NORMAL_BORDER_WIDTH,
    },
    hovered: {
        vertexRadius: {
            'Point': 8,
            'LineString': 4,
            'Polygon': 4,
        },
        color: '#deffde',
        width: DEFAULT_SHAPE_SETTINGS.NORMAL_WIDTH,
        borderWidth: DEFAULT_SHAPE_SETTINGS.THICK_BORDER_WIDTH,
    },
    selected: {
        vertexRadius: {
            'Point': 8,
            'LineString': 4,
            'Polygon': 4,
        },
        color: '#73ffcc',
        width: DEFAULT_SHAPE_SETTINGS.THICK_WIDTH,
        borderWidth: DEFAULT_SHAPE_SETTINGS.THICK_BORDER_WIDTH,
    },
    edited: {
        vertexRadius: {
            'Point': 8,
            'LineString': 4,
            'Polygon': 4,
        },
        color: '#daff99',
        width: DEFAULT_SHAPE_SETTINGS.THICK_WIDTH,
        borderWidth: DEFAULT_SHAPE_SETTINGS.THICK_BORDER_WIDTH,
    },
};

export enum DRAWN_TOOL {
    ANNOTATION = 'annotation',
    QUICK_OBSERVATION = 'observation',
    MEASURE = 'measure',
    ELEVATION_PROFILE = 'elevation_profile',
}

/**
 * Mode for the `DrawTool`
 */
export enum DRAWTOOL_MODE {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',
}

export enum ANNOTATION_CREATESTATE {
    NONE = 'none',
    SELECT = 'select',
    DRAWING = 'draw',
    DETAILS = 'details',
    SUMBIT = 'submit',
    COMPLETE = 'complete',
}

export enum EDITSTATE {
    NONE = 'none',
    DRAWING = 'draw',
    DETAILS = 'details',
    SUMBIT = 'submit',
    COMPLETE = 'complete',
    VIEW = 'view',
}

export enum SSDM_GEOMETRY_TYPE {
    POLYLINE = 'Polyline',
    POLYGON = 'Polygon',
    POINT = 'Point',
}

export enum SSDM_CLASSIFICATION_TYPES {
    SYMBOL_LINE_CHANNEL = 'Channel',
    SYMBOL_LINE_CONTOURS = 'Contours',
    SYMBOL_LINE_FAULT = 'Fault',
    SYMBOL_LINE_GEOLOGIC_FEATURE = 'Geologic Feature',
    SYMBOL_LINE_ISOLINES = 'Isolines',
    SYMBOL_LINE_SEABED_FEATURE = 'Seabed Feature',
    SYMBOL_LINE_SURVEY_TRACK = 'Survey Track',
    SYMBOL_POINT_ACOUSTIC = 'Acoustic',
    SYMBOL_POINT_GEOTECHNICAL = 'Geotechnical',
    SYMBOL_POINT_SEABED_FEATURE = 'Seabed Feature',
    SYMBOL_POINT_SOUNDING_GRID = 'Sounding Grid',
    SYMBOL_POINT_SURVEY_SHOT = 'Survey Shot',
    SYMBOL_POINT_TSDip = 'TSDip',
    SYMBOL_POLYGON_ACOUSTIC = 'Acoustic',
    SYMBOL_POLYGON_CHART = 'Chart',
    SYMBOL_POLYGON_EQUIPMENT_LIMITS = 'Equipment Limits',
    SYMBOL_POLYGON_FAULT = 'Fault',
    SYMBOL_POLYGON_GEOLOGIC_FEATURE = 'Geologic Feature',
    SYMBOL_POLYGON_SEABED_FEATURE = 'Seabed Feature',
    SYMBOL_POLYGON_SEDIMENT = 'Sediment',
    SYMBOL_POLYGON_SURVEY_TYPE = 'Survey Type',
}

export enum ROLES {
    OWNER = 'owner',
    MEMBER = 'member',
    PENDING = 'pending',
}

export enum GEOJSON_DISPLAY_MODE {
    GEOMETRY = 'geometry',
    PROPERTY = 'property',
}

export enum GEOJSON_AMPLITUDE_MODE {
    NORMALIZED_SIGNAL = 'normalized signal',
    FROM_MAX_DEVIATION = 'dev. from max',
    FROM_MIN_DEVIATION = 'dev. from min',
    FROM_MEAN_DEVIATION = 'dev. from mean',
}

export const DEFAULT_GIRO3D_SETTINGS = {
    'HILLSHADE': true,
    'HILLSHADE_INTENSITY': 0.7,
    'HILLSHADE_AZIMUTH': 315,
    'HILLSHADE_ZENITH': 45,
    'GRID': false,
    'SEISMIC_GRID': false,
    'GRID_COLOR': new Color('white'),
    'GRID_LABELS': true,
    'GRID_CEILING': true,
    'GRID_SIDES': true,
    'GRID_OPACITY': 1,
    'CONTOUR_LINES': false,
    'CONTOUR_LINES_PRIMARY_INTERVAL': 50,
    'CONTOUR_LINES_SECONDARY_INTERVAL': 10, // has to be a multiple of the primary interval
    'CONTOUR_LINES_OPACITY': 1,
    'CONTOUR_LINES_COLOR': new Color('#000000'),
    'POINTCLOUD_EDL': true,
    'POINTCLOUD_INPAINTING': false,
    'Z_SCALE': 1,
    'MAP_SEGMENTS': 64,
    'INSPECTOR': false,
    'CONTINUOUS_PICKING_MIN_INTERVAL': 100, // in milliseconds
};

export const DEFAULT_LINE_SETTINGS = {
    'RAYCAST_THRESHOLD': 10,
};

export const DEFAULT_LAYER_SETTINGS = {
    'OPACITY': 1,
    'OVERLAY_COLOR': new Color(0xffffff),
    'Z_OFFSET': 0,
    'COLORMAP': COLORMAP_NAMES.GRAYSCALE,
    'CLIPPING': false,
    'CLIPPING_RANGE': undefined,
};

export const DEFAULT_ELEVATION_SETTINGS = {
    'OVERLAY_COLOR': new Color(0x173958),
    'COLORMAP': COLORMAP_NAMES.C_DEEP,
};

export const DEFAULT_COPC_SETTINGS = {
    'POINT_SIZE': 2,
    'SUBDIVISION_THRESHOLD': 1,
    'MIN_SUBDIVISION_THRESHOLD': 0.2,
    'MAX_SUBDIVISION_THRESHOLD': 4,
};

export const DEFAULT_POINTCLOUD_SETTINGS = {
    'MODE': MODE.ELEVATION,
    'POINT_SIZE': 2,
    'MIN_POINT_SIZE': 0.5,
    'MAX_POINT_SIZE': 16,
    'SSE_THRESHOLD': 16,
    'MIN_SSE_THRESHOLD': 1,
    'MAX_SSE_THRESHOLD': 64,
    'OVERLAY_COLOR': new Color(0xffffff),
    'ELEVATION_OVERLAY_COLOR': new Color(0xe24a33),
};

export const DEFAULT_GEOJSON_SETTINGS = {
    'DISPLAY_MODE': GEOJSON_DISPLAY_MODE.GEOMETRY,
    'OVERLAY_COLOR': new Color(0x000000),
    'AMPLITUDE_SPACTIAL_RANGE': 50,
    'AMPLITUDE_SHOWN': false,
    'AMPLITUDE_MODE': GEOJSON_AMPLITUDE_MODE.NORMALIZED_SIGNAL,
};

export const DEFAULT_TRACKLINE_SETTINGS = {
    'ARROWS': false,
    'ARROW_SPACING': 200,
    'ARROW_SCALE': 1,
    'THICKNESS': 1.5,
};

export const DEFAULT_SEGY_SETTINGS = {
    'OFFSET': 0.0,
    'INTENSITY_FILTER': 0.01,
    'CROSS_SECTION_INVERTED': false,
    'IS_VIEWING_CROSS_SECTION': false,
    'STATIC_CORRECTION': true,
    'FILTER_TRANSPARENCY': false,
    'COLORMAP_STANDARD': COLORMAP_NAMES.SEISMIC,
    'COLORMAP_LESS_THAN_0': COLORMAP_NAMES.LANDMARK,
    'SPEEDMODULE_MS': 1490,
    'ACTIVE_FILE': undefined,
    'INLINE_DIRECTION': 0,
    'CROSSLINE_DIRECTION': 90,
    'DIRECTION_TOLERANCE': 20,
    'ORDER_MODE': ORDER_MODE.LINE,
    'SEARCH_TERM': '',
};

export const DEFAULT_BOREHOLE_SETTINGS = {
    'DISPLAY_MODE': GEOJSON_DISPLAY_MODE.GEOMETRY,
    'RADIUS': 5,
    'RADIAL_SEGMENTS': 16,
    'OVERLAY_COLOR': new Color(0x8d99ae),
};

export enum FILTER_DISPLAY_MODE {
    HIDE = 'hide',
    STYLE = 'style',
}

export const DEFAULT_VECTOR_SETTINGS = {
    'POINT_SIZE': 1,
    'LINE_WIDTH': 6,
    'FILTER_MODE': FILTER_DISPLAY_MODE.HIDE,
    'BORDER_COLOR': new Color(0xffffff),
    'BORDER_WIDTH': 0.5,
    'BORDER_OPACITY': 1,
    'FILL_COLOR': new Color(0x000000),
    'FILL_OPACITY': 0.4,
};

export const DEFAULT_CABLE_PIPELINE_SETTINGS = {
    'RADIUS': 2,
    'RADIAL_SEGMENTS': 12,
    'OVERLAY_COLOR': new Color(0x8d99ae),
    'COLORMAP': COLORMAP_NAMES.SPECTRAL,
};

export enum PANE {
    GIRO3D_VIEWPORT = 'giro3d',
    ELEVATION_PROFILE_CHART = 'elevation_profile_chart',
    ATTRIBUTE_TABLE = 'attribute_table',
    LEGEND = 'legend',
    DATASETS = 'datasets',
    INSPECTOR = 'inspector',
    ANNOTATIONS = 'annotations',
    ANNOTATION = 'annotation',
    ANNOTATION_TOOL = 'annotation_tool',
    UPLOADS = 'upload',
    MEASURE = 'measure',
    FEATURE_INFO = 'feature_info',
    SELECTION = 'selection',
    VIEWS = 'views',
    MINIMAP = 'minimap',
    SEISMIC = 'seismic',
    CHART = 'chart',
    STORY = 'story',
}

export enum POSITION {
    LEFT = 'left',
    RIGHT = 'right',
    TRAY = 'tray',
    CENTER = 'center',
}

export enum PANE_TYPE {
    DATASET = 'dataset',
    DATASET_CONFIG = 'dataset_config',
    ANNOTATION = 'annotation',
    STORY = 'story',
    DATA = 'data',
    SINGLE = 'single',
}

export const PANE_PROPERTIES: {
    [key in PANE]: {
        defaultPosition: POSITION;
        positions: POSITION[];
        type: PANE_TYPE;
        name: string;
        labeledName?: (string) => string;
        pinnable?: boolean;
    };
} = {
    [PANE.GIRO3D_VIEWPORT]: {
        defaultPosition: POSITION.CENTER,
        positions: [POSITION.CENTER],
        type: PANE_TYPE.SINGLE,
        name: 'Viewport',
    },
    [PANE.ELEVATION_PROFILE_CHART]: {
        defaultPosition: POSITION.TRAY,
        positions: [POSITION.TRAY],
        type: PANE_TYPE.DATA,
        name: 'Elevation Profile',
    },
    [PANE.ATTRIBUTE_TABLE]: {
        defaultPosition: POSITION.TRAY,
        positions: [POSITION.TRAY],
        type: PANE_TYPE.DATASET,
        name: 'Attribute Table',
        labeledName: (datasetName) => `${datasetName} - Attributes`,
    },
    [PANE.LEGEND]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.SINGLE,
        name: 'Legends',
    },
    [PANE.DATASETS]: {
        defaultPosition: POSITION.LEFT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.SINGLE,
        name: 'Datasets',
    },
    [PANE.INSPECTOR]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.DATASET,
        name: 'Inspector',
        labeledName: (datasetName) => `${datasetName} - Inspector`,
    },
    [PANE.ANNOTATIONS]: {
        defaultPosition: POSITION.LEFT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.SINGLE,
        name: 'Annotations',
    },
    [PANE.ANNOTATION]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.ANNOTATION,
        name: 'Annotation',
        labeledName: (annotationName) => annotationName,
    },
    [PANE.ANNOTATION_TOOL]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.RIGHT, POSITION.LEFT],
        type: PANE_TYPE.SINGLE,
        name: 'Annotation Tool',
    },
    [PANE.UPLOADS]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.SINGLE,
        name: 'Uploads',
    },
    [PANE.MEASURE]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.SINGLE,
        name: 'Measure',
    },
    [PANE.FEATURE_INFO]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.SINGLE,
        name: 'Feature Info',
    },
    [PANE.SELECTION]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.SINGLE,
        name: 'Selection',
        pinnable: true,
    },
    [PANE.VIEWS]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.SINGLE,
        name: 'Views',
    },
    [PANE.MINIMAP]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT, POSITION.TRAY],
        type: PANE_TYPE.SINGLE,
        name: 'Minimap',
    },
    [PANE.SEISMIC]: {
        defaultPosition: POSITION.TRAY,
        positions: [POSITION.TRAY],
        type: PANE_TYPE.SINGLE,
        name: 'Seismic Viewer',
    },
    [PANE.CHART]: {
        defaultPosition: POSITION.TRAY,
        positions: [POSITION.TRAY],
        type: PANE_TYPE.DATASET_CONFIG,
        name: 'Chart',
        labeledName: (datasetName) => `${datasetName} - Charts`,
    },
    [PANE.STORY]: {
        defaultPosition: POSITION.RIGHT,
        positions: [POSITION.LEFT, POSITION.RIGHT],
        type: PANE_TYPE.STORY,
        name: 'Story',
        labeledName: (storyName) => storyName,
    },
};
