import { UncontrolledTooltip } from 'reactstrap';

const ToolbarButton = (props: {
    title: string;
    iconName: string;
    id: string;
    onClick: () => void;
    disabledMessage?: string;
    counter?: string;
    highlight?: boolean;
    className?: string;
}) => (
    <button
        type="button"
        id={props.id}
        aria-label={props.title}
        onClick={props.onClick}
        disabled={props.disabledMessage !== undefined}
        className={`${props.className} ${props.highlight ? 'highlight' : ''}`}
    >
        <i className={`${props.iconName}`} />
        <span>{props.counter}</span>
        <UncontrolledTooltip placement="bottom" target={props.id} fade={false} hideArrow>
            <span className={props.disabledMessage ? '' : 'menu-tooltip'}>{props.disabledMessage || props.title}</span>
        </UncontrolledTooltip>
    </button>
);

export default ToolbarButton;
