import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';
import {
    DatasetId,
    SerializableBox3,
    SerializableVector3,
    SourceFileId,
    toBox3,
    toVector3,
    PlayState,
    ViewId,
    Integer,
    UUID,
} from 'types/common';
import { Feature } from 'ol';
import { SerializedState, StoryId, StoryMap, View } from 'types/serialization/View';
import { RootState } from 'store';
import { CONTROLS_MODE } from 'services/Controls';
import { AnnotationId } from 'types/Annotation';

export type FollowCamera = {
    state: PlayState;
    modal: boolean;
    datasetId: DatasetId;
    rotateCamera: boolean;
    fixedDepth: boolean;
    depth: number;
    altitude: number;
    speed: number;
    progress: number;
};

export type ContextMenu = {
    open: boolean;
    offset?: { x: number; y: number };
    dataset?: DatasetId;
    sourceFile?: SourceFileId;
    annotation?: AnnotationId;
    point?: SerializableVector3;
};

export type Selection = {
    feature?: Feature;
    sourceFileId?: SourceFileId;
    datasetId?: DatasetId;
    annotationId?: AnnotationId;
};

export type State = {
    initialized: boolean;
    controls: {
        mode: CONTROLS_MODE;
        target: SerializableVector3;
    };
    // TODO remove this property since it is not longer necessary
    // now that we are using the Redux store for all layer updates
    revision: number;
    camera: {
        position: SerializableVector3;
        target: SerializableVector3;
        zoom: number;
        focalOffset: SerializableVector3;
    };
    contextTool: ContextMenu;
    sceneVolume: SerializableBox3;
    zScale: number;
    showInspector: boolean;
    seismicView: {
        initialized: boolean;
        volume: SerializableBox3;
    };
    followCamera: FollowCamera;
    selectionShown: boolean;
    coordinates: boolean;
    axisCompass: boolean;
    selection: Selection;
    loadedView: ViewId;
    promptedView: SerializedState;
    views: SerializedState[];
    stories: StoryMap[];
    activeStory: StoryId;
    storyIndex: Integer;
};

const initialState: State = {
    initialized: null,
    controls: {
        mode: CONTROLS_MODE.PAN,
        target: [0, 0, 0],
    },
    revision: 0,
    showInspector: false,
    camera: null,
    contextTool: { open: false, dataset: null, sourceFile: null, offset: { x: 0, y: 0 }, point: null },
    sceneVolume: null,
    seismicView: {
        initialized: false,
        volume: null,
    },
    zScale: DEFAULT_GIRO3D_SETTINGS.Z_SCALE,
    followCamera: {
        state: PlayState.Pause,
        modal: false,
        datasetId: undefined,
        rotateCamera: false,
        fixedDepth: false,
        depth: 10,
        altitude: 10,
        speed: 10,
        progress: 0,
    },
    selectionShown: false,
    coordinates: false,
    axisCompass: true,
    selection: undefined,
    loadedView: undefined,
    promptedView: undefined,
    views: undefined,
    stories: undefined,
    activeStory: undefined,
    storyIndex: undefined,
};

const self = (store: RootState) => store.giro3d;

// Selectors
export const getZScale = createSelector(self, (s) => s.zScale);
export const isInitialized = createSelector(self, (s) => s.initialized);
export const isSeismicViewInitialized = createSelector(self, (s) => s.seismicView.initialized);
export const getRevision = createSelector(self, (s) => s.revision);
export const getContextMenu = createSelector(self, (s) => s.contextTool);
export const getVolume = createSelector(
    (s) => self(s).sceneVolume,
    (sceneVolume) => toBox3(sceneVolume)
);
export const showInspector = createSelector(self, (s) => s.showInspector);
export const getCamera = createSelector(
    (s) => self(s).camera,
    (camera) => ({
        position: toVector3(camera?.position),
        target: toVector3(camera?.target),
        zoom: camera?.zoom,
        focalOffset: toVector3(camera?.focalOffset ?? [0, 0, 0]),
    })
);
export const getControls = createSelector(self, (s) => s.controls);
export const getControlMode = createSelector(getControls, (controls) => controls.mode);
export const getControlsTarget = createSelector(getControls, (controls) => controls.target);

export const getFollowCamera = createSelector(self, (s) => s.followCamera);

export const getSelectionShown = createSelector(self, (s) => s.selectionShown);
export const getCoordinatesShown = createSelector(self, (s) => s.coordinates);
export const getAxisCompassShown = createSelector(self, (s) => s.axisCompass);

export const getSelection = createSelector(self, (s) => s.selection);
export const getSeismicVolume = createSelector(
    (s) => self(s).seismicView.volume,
    (volume) => toBox3(volume)
);

export const getLoadedView = createSelector(self, (s) => s.loadedView);
export const getPromptedView = createSelector(self, (s) => s.promptedView);
export const getViews = createSelector(self, (s) => s.views);

export const getStories = createSelector(self, (s) => s.stories);
export const getStory = (id: UUID) => createSelector(self, (s) => s.stories.find((story) => story.id === id));
export const getActiveStory = createSelector(self, (s) => s.activeStory);
export const getStoryIndex = createSelector(self, (s) => s.storyIndex);

const slice = createSlice({
    name: 'giro3d',
    initialState,
    reducers: {
        loadView: (state, action: PayloadAction<{ view: View; id: ViewId }>) => {
            const settings = action.payload.view.view;

            state.showInspector =
                settings.inspectorVisible !== undefined ? settings.inspectorVisible : state.showInspector;
            state.zScale = settings.zScale ?? state.zScale;
            state.camera = {
                position: settings.camera ? settings.camera : null,
                target: settings.target ? settings.target : null,
                zoom: settings.zoom ? settings.zoom : 1,
                focalOffset: settings.focalOffset ? settings.focalOffset : null,
            };
            state.selectionShown =
                settings.selectionShown !== undefined ? settings.selectionShown : state.selectionShown;
            state.axisCompass = settings.axisCompass !== undefined ? settings.axisCompass : state.axisCompass;
            state.coordinates = settings.coordinates !== undefined ? settings.coordinates : state.coordinates;
            state.loadedView = action.payload.id;
        },
        initialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        seismicViewInitialized: (state, action: PayloadAction<boolean>) => {
            state.seismicView.initialized = action.payload;
        },
        setCamera: (
            state,
            action: PayloadAction<{
                position: SerializableVector3;
                target: SerializableVector3;
                zoom: number;
                focalOffset: SerializableVector3;
            }>
        ) => {
            const { position, target, zoom, focalOffset } = action.payload;

            state.camera = {
                position,
                target,
                zoom,
                focalOffset,
            };
        },
        setControlsMode: (state, action: PayloadAction<CONTROLS_MODE>) => {
            state.controls.mode = action.payload;
        },
        setControlsTarget: (state, action: PayloadAction<SerializableVector3>) => {
            state.controls.target = action.payload;
        },
        volume: (state, action: PayloadAction<SerializableBox3>) => {
            state.sceneVolume = action.payload;
        },
        seismicVolume: (state, action: PayloadAction<SerializableBox3>) => {
            state.seismicView.volume = action.payload;
        },
        setZScale: (state, action: PayloadAction<number>) => {
            state.zScale = action.payload;
        },
        resetZScale: (state) => {
            state.zScale = DEFAULT_GIRO3D_SETTINGS.Z_SCALE;
        },
        incrementRevision: (state) => {
            state.revision++;
        },
        contextMenu: (state, action: PayloadAction<ContextMenu>) => {
            state.contextTool = action.payload;
        },
        showInspector: (state, action: PayloadAction<boolean>) => {
            state.showInspector = action.payload;
        },
        setFollowCameraModal: (state, action: PayloadAction<boolean>) => {
            state.followCamera.modal = action.payload;
        },
        setFollowCamera: (state, action: PayloadAction<FollowCamera>) => {
            state.followCamera = action.payload;
        },
        setFollowCameraState: (state, action: PayloadAction<PlayState>) => {
            state.followCamera.state = action.payload;
        },
        setFollowCameraProgress: (state, action: PayloadAction<number>) => {
            state.followCamera.progress = action.payload;
        },
        resetFollowCamera: (state) => {
            state.followCamera = initialState.followCamera;
        },
        setSelectionShown: (state, action: PayloadAction<boolean>) => {
            state.selectionShown = action.payload;
        },
        setCoordinatesShown: (state, action: PayloadAction<boolean>) => {
            state.coordinates = action.payload;
        },
        setAxisCompassShown: (state, action: PayloadAction<boolean>) => {
            state.axisCompass = action.payload;
        },
        setSelection: (state, action: PayloadAction<Selection>) => {
            state.selection = action.payload;
        },
        setPromptedView: (state, action: PayloadAction<SerializedState>) => {
            state.promptedView = action.payload;
        },
        setViews: (state, action: PayloadAction<SerializedState[]>) => {
            state.views = action.payload;
        },
        setStories: (state, action: PayloadAction<StoryMap[]>) => {
            state.stories = action.payload;
        },
        setStory: (state, action: PayloadAction<StoryMap>) => {
            state.stories = state.stories
                ? [...state.stories.filter((s) => s.id !== action.payload.id), action.payload]
                : [action.payload];
        },
        setActiveStory: (state, action: PayloadAction<StoryId>) => {
            state.activeStory = action.payload;
        },
        setStoryIndex: (state, action: PayloadAction<Integer>) => {
            state.storyIndex = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const loadView = slice.actions.loadView;
export const setZScale = slice.actions.setZScale;
export const setVolume = slice.actions.volume;
export const setSeismicVolume = slice.actions.seismicVolume;
export const resetZScale = slice.actions.resetZScale;
export const layerSettingsChanged = slice.actions.incrementRevision;
export const setInitialized = slice.actions.initialized;
export const setSeismicViewInitialized = slice.actions.seismicViewInitialized;
export const setDeserialized = slice.actions.incrementRevision;
export const setContextMenu = slice.actions.contextMenu;
export const setShowInspector = slice.actions.showInspector;
export const setCamera = slice.actions.setCamera;
export const setControlsMode = slice.actions.setControlsMode;
export const setControlsTarget = slice.actions.setControlsTarget;
export const setFollowCameraModal = slice.actions.setFollowCameraModal;
export const setFollowCamera = slice.actions.setFollowCamera;
export const setFollowCameraState = slice.actions.setFollowCameraState;
export const setFollowCameraProgress = slice.actions.setFollowCameraProgress;
export const resetFollowCamera = slice.actions.resetFollowCamera;
export const setSelectionShown = slice.actions.setSelectionShown;
export const setCoordinatesShown = slice.actions.setCoordinatesShown;
export const setAxisCompassShown = slice.actions.setAxisCompassShown;
export const setSelection = slice.actions.setSelection;
export const setPromptedView = slice.actions.setPromptedView;
export const setViews = slice.actions.setViews;
export const setStories = slice.actions.setStories;
export const setStory = slice.actions.setStory;
export const setActiveStory = slice.actions.setActiveStory;
export const setStoryIndex = slice.actions.setStoryIndex;
