// React
import { useState, useEffect } from 'react';
import { Button, Modal, Popover, PopoverBody } from 'reactstrap';

import { getBaseName, SourceFile } from 'types/SourceFile';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import {
    LAYER_STATES,
    SOURCE_FILE_STATES,
    ORDER_MODE,
    LAYER_TYPES,
    PANE,
    LAYER_DATA_TYPES,
    isRasterType,
} from 'services/Constants';
import { supportedTypesLabels, supportedDataTypesLabels } from 'services/Labels';
import { useEventBus } from 'EventBus';
import { setClickedDataset, pollForDatasets, pollForSourcefiles } from 'redux/actions';
import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';

// API
import DosApi from 'services/DosApi';

// Components
import ToggleSwitch from 'components/ToggleSwitch';
import useInterval from 'services/useInterval';
import { canToggleSourceFiles, hasAttributes, HasSeismicPlane, hasSeismicPlane, LayerState } from 'types/LayerState';
import { DatasetId } from 'types/common';
import { organizeLines, groupName } from 'services/seismicSorter';
import Select, { Option } from 'components/Select';
import ToleranceInput from 'components/dropdown/ToleranceInput';
import TextInput from 'components/dropdown/TextInput';
import Dataset, { SingleBandCogDatasetProperties, MultiBandCogDatasetProperties } from 'types/Dataset';
import proj4 from 'proj4';
import ErrorBoundary from '../../../ErrorBoundary';
import SourceFileListItem from './SourceFileListItem';
import DatasetSettingsPopover from '../DatasetSettingsPopover';

import UploadFilesModalForm from '../../forms/UploadFilesModalForm';

import SourceFileTable from '../SourceFileTable';
import InlineDropMenu from '../../../InlineDropMenu';
import RemoveDataset from '../RemoveDataset';
import DatasetIndicator from '../DatasetIndicator';

const DefaultList = (props: { dataset: Dataset; sourcefiles: SourceFile[]; onEditSourceFile; canToggleSourceFile }) => {
    const { dataset, sourcefiles, onEditSourceFile, canToggleSourceFile } = props;

    return sourcefiles?.map((sourceFile) => (
        <SourceFileListItem
            key={sourceFile.id}
            sourceFile={sourceFile}
            datasetId={dataset.id}
            onEditSourceFile={onEditSourceFile}
            canToggleVisibility={canToggleSourceFile}
        />
    ));
};

const SeismicList = (props: {
    dataset: Dataset;
    sourcefiles: SourceFile[];
    layerState: LayerState & HasSeismicPlane;
    onEditSourceFile;
    canToggleSourceFile;
}) => {
    const { dataset, sourcefiles, layerState, onEditSourceFile, canToggleSourceFile } = props;

    const inlineDirection = useAppSelector(layersSlice.getInlineDirection(layerState));
    const crosslineDirection = useAppSelector(layersSlice.getCrosslineDirection(layerState));
    const inlineTolerance = useAppSelector(layersSlice.getInlineTolerance(layerState));
    const crosslineTolerance = useAppSelector(layersSlice.getCrosslineTolerance(layerState));
    const inlineTerm = useAppSelector(layersSlice.getInlineTerm(layerState));
    const crosslineTerm = useAppSelector(layersSlice.getCrosslineTerm(layerState));
    const lineOrder = useAppSelector(layersSlice.getLineOrderMode(layerState));

    const [inlineOpen, setInlineOpen] = useState(true);
    const [crosslineOpen, setCrosslineOpen] = useState(true);
    const [otherOpen, setOtherOpen] = useState(true);

    const groupSeismic = () => {
        if (lineOrder === ORDER_MODE.LINE)
            return organizeLines(sourcefiles, inlineDirection, crosslineDirection, inlineTolerance, crosslineTolerance);
        if (lineOrder === ORDER_MODE.FILE) return groupName(sourcefiles, inlineTerm, crosslineTerm);
        return { inline: [], crossline: [], other: [] };
    };

    const organizedLines =
        dataset.type === LAYER_TYPES.SEISMIC ? groupSeismic() : { inline: [], crossline: [], other: [] };

    if (lineOrder === ORDER_MODE.FILE)
        return DefaultList({
            dataset,
            sourcefiles,
            onEditSourceFile,
            canToggleSourceFile,
        });
    return (
        <>
            <li>
                <div className="dropdown">
                    <label htmlFor="inline-dropdown">
                        <span>Inline ({organizedLines.inline.length})</span>
                        <input
                            type="checkbox"
                            id="inline-dropdown"
                            onChange={(e) => setInlineOpen(e.target.checked)}
                            checked={inlineOpen}
                        />
                    </label>
                </div>
                <ul>
                    {inlineOpen
                        ? organizedLines.inline?.map((sourceFile) => (
                              <SourceFileListItem
                                  key={sourceFile.id}
                                  sourceFile={sourceFile}
                                  datasetId={dataset.id}
                                  onEditSourceFile={onEditSourceFile}
                                  canToggleVisibility={canToggleSourceFile}
                              />
                          ))
                        : null}
                </ul>
            </li>
            <li>
                <div className="dropdown">
                    <label htmlFor="crossline-dropdown">
                        <span>Crossline ({organizedLines.crossline.length})</span>
                        <input
                            type="checkbox"
                            id="crossline-dropdown"
                            onChange={(e) => setCrosslineOpen(e.target.checked)}
                            checked={crosslineOpen}
                        />
                    </label>
                </div>
                <ul>
                    {crosslineOpen
                        ? organizedLines.crossline?.map((sourceFile) => (
                              <SourceFileListItem
                                  key={sourceFile.id}
                                  sourceFile={sourceFile}
                                  datasetId={dataset.id}
                                  onEditSourceFile={onEditSourceFile}
                                  canToggleVisibility={canToggleSourceFile}
                              />
                          ))
                        : null}
                </ul>
            </li>
            <li>
                <div className="dropdown">
                    <label htmlFor="other-dropdown">
                        <span>Other ({organizedLines.other.length})</span>
                        <input
                            type="checkbox"
                            id="other-dropdown"
                            onChange={(e) => setOtherOpen(e.target.checked)}
                            checked={otherOpen}
                        />
                    </label>
                </div>
                <ul>
                    {otherOpen
                        ? organizedLines.other?.map((sourceFile) => (
                              <SourceFileListItem
                                  key={sourceFile.id}
                                  sourceFile={sourceFile}
                                  datasetId={dataset.id}
                                  onEditSourceFile={onEditSourceFile}
                                  canToggleVisibility={canToggleSourceFile}
                              />
                          ))
                        : null}
                </ul>
            </li>
        </>
    );
};

const SeismicOptions = (props: { layerState: LayerState & HasSeismicPlane }) => {
    const { layerState } = props;
    const datasetId = layerState.datasetId;

    const dispatch = useAppDispatch();

    const [seismicSettingsOpen, setSeismicSettingsOpen] = useState(false);
    const toggleSeismicSettings = () => {
        setSeismicSettingsOpen(!seismicSettingsOpen);
    };

    const inlineDirection = useAppSelector(layersSlice.getInlineDirection(layerState));
    const crosslineDirection = useAppSelector(layersSlice.getCrosslineDirection(layerState));
    const inlineTolerance = useAppSelector(layersSlice.getInlineTolerance(layerState));
    const crosslineTolerance = useAppSelector(layersSlice.getCrosslineTolerance(layerState));
    const inlineTerm = useAppSelector(layersSlice.getInlineTerm(layerState));
    const crosslineTerm = useAppSelector(layersSlice.getCrosslineTerm(layerState));
    const lineOrder = useAppSelector(layersSlice.getLineOrderMode(layerState));

    const orderOptions = [
        { label: 'None', value: ORDER_MODE.FILE },
        { label: 'Line Direction', value: ORDER_MODE.LINE },
        { label: 'File Name', value: ORDER_MODE.NAME },
    ];

    return (
        <>
            <label htmlFor="orderingOptions">Sort by: </label>
            <Select
                options={orderOptions}
                // value={orderOptions.find((o) => o.value === lineOrder)}
                onChange={(v) => {
                    dispatch(
                        layersSlice.setLineOrder({
                            layer: layerState,
                            value: (v as Option<ORDER_MODE>).value,
                        })
                    );
                }}
                className="orderingOptions"
                id="orderingOptions"
            />
            <Button
                className="borderless light-blue"
                id={`seismic-settings-${datasetId}`}
                title="Seismic Settings"
                onClick={toggleSeismicSettings}
            >
                <i className="fas fa-gear" />
            </Button>
            <Popover
                isOpen={seismicSettingsOpen}
                toggle={toggleSeismicSettings}
                target={`seismic-settings-${datasetId}`}
                hideArrow
                trigger="legacy"
            >
                <PopoverBody>
                    <ul>
                        {lineOrder === ORDER_MODE.NAME ? (
                            <>
                                <TextInput
                                    title="Inline Search Term"
                                    value={inlineTerm}
                                    onChange={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setInlineTerm({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                />
                                <TextInput
                                    title="Crossline Search Term"
                                    value={crosslineTerm}
                                    onChange={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setCrosslineTerm({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                />
                            </>
                        ) : null}
                        {lineOrder === ORDER_MODE.LINE ? (
                            <>
                                <ToleranceInput
                                    title="Inline Direction"
                                    value={inlineDirection}
                                    min={0}
                                    max={360}
                                    step={1}
                                    unit="°"
                                    icon="fas fa-arrow-up-long"
                                    onChange={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setInlineDirection({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                    iconRotation={inlineDirection}
                                    tolerance={inlineTolerance}
                                    minTolerance={0}
                                    maxTolerance={90}
                                    onChangeTolerance={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setInlineTolerance({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                />
                                <ToleranceInput
                                    title="Crossline Direction"
                                    value={crosslineDirection}
                                    min={0}
                                    max={360}
                                    step={1}
                                    unit="°"
                                    icon="fas fa-arrow-up-long"
                                    onChange={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setCrosslineDirection({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                    iconRotation={crosslineDirection}
                                    tolerance={crosslineTolerance}
                                    minTolerance={0}
                                    maxTolerance={90}
                                    onChangeTolerance={(value) => {
                                        if (hasSeismicPlane(layerState))
                                            dispatch(
                                                layersSlice.setCrosslineTolerance({
                                                    layer: layerState,
                                                    value,
                                                })
                                            );
                                    }}
                                />
                            </>
                        ) : null}
                        {lineOrder === ORDER_MODE.FILE ? <li>No Settings</li> : null}
                    </ul>
                </PopoverBody>
            </Popover>
        </>
    );
};

const hasResolution = (
    properties: Dataset['properties']
): properties is SingleBandCogDatasetProperties | MultiBandCogDatasetProperties => {
    return 'resolution' in properties;
};

const getResolutionUnit = (projection: number): string => {
    try {
        const projDef = proj4.defs(`EPSG:${projection}`);
        if (!projDef) return 'Unknown';

        // Proj4 stores unit info in the units property of the projection definition
        const units = projDef.units;
        if (!units) return 'Unknown';

        return units;
    } catch (e) {
        console.warn('Failed to determine projection units:', e);
        return 'Unknown';
    }
};

type Props = {
    datasetId: DatasetId;
    temporary?: boolean;
    short?: boolean;
};

const InspectDatasetMenu = (props: Props) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();

    const datasetId = props.datasetId;
    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const sourcefiles = useAppSelector(datasetsSlice.getSourceFiles(datasetId));
    const clickedSourceFileId = useAppSelector(giro3dSlice.getSelection).sourceFileId;

    const uploading = useAppSelector(datasetsSlice.getUploading(datasetId));

    const layerState = useAppSelector(layersSlice.get(datasetId));
    const isVisible = useAppSelector(layersSlice.isVisibleSelf(layerState));
    const disabled = dataset.state !== LAYER_STATES.LOADED && dataset.state !== LAYER_STATES.ACTIVE;
    const canToggleSourceFile = canToggleSourceFiles(layerState);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const [manageFilesModal, setManageFilesModal] = useState(false);
    const [uploadFilesModal, setUploadFilesModal] = useState(false);

    const [attriubtesVisible, setAttributesVisible] = useState(false);
    const [filesVisible, setFilesVisible] = useState(false);

    const toggleUploadFilesModal = () => {
        setUploadFilesModal(!uploadFilesModal);
    };
    const toggleManageFilesModal = () => {
        setManageFilesModal(!manageFilesModal);
    };
    const onDatasetFileUploadClose = () => {
        toggleUploadFilesModal();
        dispatch(pollForDatasets([dataset]));
    };
    const selectDataset = () => {
        dispatch(setClickedDataset(datasetId));
        eventBus.dispatch('highlight-layer', { layer: datasetId });
    };

    const onEditSourceFile = (sourceFile: SourceFile) => {
        dispatch(setClickedDataset(datasetId, sourceFile.id));
        toggleManageFilesModal();
    };

    const setVisible = (value: boolean) => {
        dispatch(layersSlice.setVisibility({ layer: layerState, value }));
    };

    function goToLayer() {
        eventBus.dispatch('go-to-layer', { layer: layerState.datasetId });
    }

    useEffect(() => {
        if (datasetId && !sourcefiles)
            DosApi.fetchDatasetSourcefiles(datasetId)
                .then((sourceFiles) => {
                    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
                })
                .catch((err) => console.log(err));
    }, [datasetId, sourcefiles]);

    useInterval(() => {
        const sourcefilesInProcess = sourcefiles.filter((s) =>
            [
                SOURCE_FILE_STATES.CONVERTING,
                SOURCE_FILE_STATES.DELETING,
                SOURCE_FILE_STATES.UPLOADING,
                SOURCE_FILE_STATES.INGESTING,
                SOURCE_FILE_STATES.INGESTED,
                SOURCE_FILE_STATES.VALIDATING,
            ].includes(s.state)
        );
        if (sourcefilesInProcess.length !== 0) dispatch(pollForSourcefiles(dataset.id, sourcefilesInProcess));
    }, 5000);

    const fileStats = useAppSelector(datasetsSlice.getDatasetFileStats(datasetId));

    const formatResolution = (ds: Dataset) => {
        if (ds.properties && hasResolution(ds.properties) && ds.properties.resolution) {
            const unit = getResolutionUnit(ds.projection);
            return `${ds.properties.resolution[0].toFixed(2)} × ${ds.properties.resolution[1].toFixed(2)} ${unit}`;
        }
        return 'Unknown';
    };

    if (dataset === null) return <div className="tabContent">No dataset selected</div>;

    return (
        <div className="tabContent fixedHeight">
            <div className="inspector-title">
                <span>{dataset.name}</span>
                <div className="grow" />
                {props.temporary ? (
                    <Button
                        className="borderless"
                        id={`dataset-pin-${datasetId}`}
                        title={`Inspect ${dataset.name}`}
                        onClick={() =>
                            eventBus.dispatch('create-dataset-pane', {
                                paneType: PANE.INSPECTOR,
                                datasetId: dataset.id,
                                showExisting: true,
                            })
                        }
                    >
                        <i className="fas fa-thumbtack" />
                    </Button>
                ) : (
                    <>
                        <DatasetIndicator id={datasetId} dataset={dataset} />
                        <InlineDropMenu id={`dataset-inspect-actions-${datasetId}`}>
                            {dataset.user_permissions.update_dataset && (
                                <RemoveDataset id={datasetId} dataset={dataset} />
                            )}
                            <Button
                                className="borderless yellow"
                                id={`dataset-inspect-goToLayer-${datasetId}`}
                                title={`${dataset.name} settings`}
                                onClick={() => {
                                    goToLayer();
                                }}
                                disabled={dataset.state !== LAYER_STATES.LOADED}
                            >
                                <i className="fas fa-location-arrow" />
                            </Button>
                            <Button
                                className="borderless yellow"
                                id={`dataset-inspect-settings-${datasetId}`}
                                title={`${dataset.name} settings`}
                                onClick={() => {
                                    selectDataset();
                                    setSettingsOpen(true);
                                }}
                                disabled={dataset.state !== LAYER_STATES.LOADED}
                            >
                                <i className="fas fa-gear" />
                            </Button>
                        </InlineDropMenu>
                        <ToggleSwitch
                            id={`dataset-inspect-visible-${datasetId}`}
                            checked={isVisible}
                            onChange={(e) => setVisible(e.target.checked)}
                            disabled={disabled}
                        />
                        <DatasetSettingsPopover
                            dataset={dataset}
                            target={`dataset-inspect-actions-${datasetId}`}
                            isOpen={settingsOpen}
                            onToggle={() => setSettingsOpen(false)}
                        />
                    </>
                )}
            </div>
            {props.short ? (
                <div>
                    {clickedSourceFileId ? getBaseName(sourcefiles.find((s) => s.id === clickedSourceFileId)) : ''}
                </div>
            ) : null}
            <ErrorBoundary
                dispatch={dispatch}
                fallback={
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in the sourcefile list.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                }
            >
                <div className="scrollable">
                    {/* Dataset info */}
                    <div>Metadata</div>
                    <table className="firstColumn">
                        <tbody>
                            {/* <tr>
                                    <td>Owner</td>
                                    <td>
                                        {organizations
                                            ? organizations.find((e) => e.id === dataset.organization_id)
                                                ?.display_name
                                            : 'Loading...'}
                                    </td>
                                </tr> */}
                            <tr>
                                <td>Projection</td>
                                <td colSpan={2}>EPSG:{dataset.projection}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td colSpan={2}>{supportedTypesLabels[dataset.type]}</td>
                            </tr>
                            <tr>
                                <td>Data type</td>
                                <td colSpan={2}>{supportedDataTypesLabels[dataset.datatype]}</td>
                            </tr>
                            {isRasterType(dataset.datatype as LAYER_DATA_TYPES) && (
                                <tr>
                                    <td>Resolution</td>
                                    <td colSpan={2}>{formatResolution(dataset)}</td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={3} className="title">
                                    <button
                                        type="button"
                                        className="attributes-dropdown"
                                        onClick={() => setFilesVisible(!filesVisible)}
                                    >
                                        Files ({fileStats.total})
                                        <i className={`fas ${filesVisible ? 'fa-chevron-down' : 'fa-chevron-up'}`} />
                                    </button>
                                </td>
                            </tr>
                            {filesVisible && (
                                <>
                                    <tr>
                                        <td>Active</td>
                                        <td colSpan={2}>
                                            <span className="active">{fileStats.active}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Failed</td>
                                        <td colSpan={2}>
                                            <span className="failed">{fileStats.failed}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Processing</td>
                                        <td colSpan={2}>
                                            <span className="processing">{fileStats.processing}</span>
                                        </td>
                                    </tr>
                                </>
                            )}
                            {hasAttributes(layerState) ? (
                                <>
                                    <tr>
                                        <td colSpan={3} className="title">
                                            <button
                                                type="button"
                                                className="attributes-dropdown"
                                                onClick={() => setAttributesVisible(!attriubtesVisible)}
                                            >
                                                Attributes
                                                <i
                                                    className={`fas ${attriubtesVisible ? 'fa-chevron-down' : 'fa-chevron-up'}`}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                    {attriubtesVisible ? (
                                        <>
                                            <tr>
                                                <td>Attribute</td>
                                                <td>Min</td>
                                                <td>Max</td>
                                            </tr>
                                            {layerState.attributes.map((attribute) => (
                                                <tr key={attribute.id}>
                                                    <td>{attribute.name}</td>
                                                    <td>
                                                        {attribute.unit
                                                            ? `${Number(attribute.min).toLocaleString()} ${attribute.unit}`
                                                            : Number(attribute.min).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        {attribute.unit
                                                            ? `${Number(attribute.max).toLocaleString()} ${attribute.unit}`
                                                            : Number(attribute.max).toLocaleString()}
                                                    </td>
                                                </tr>
                                            ))}
                                            {layerState.unrenderedAttributes.map((attribute) => (
                                                <tr key={attribute}>
                                                    <td>{attribute}</td>
                                                    <td colSpan={2}>Non-numeric</td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                        </tbody>
                    </table>
                    {/* Files list */}
                    {props.short ? null : (
                        <>
                            <div>
                                <div>Files</div>
                                <div className="buttonRow">
                                    <Button
                                        className="borderless light-blue"
                                        id={`dataset-inspect-upload-${datasetId}`}
                                        title="Upload files"
                                        onClick={toggleUploadFilesModal}
                                    >
                                        <i className="fas fa-upload" />
                                    </Button>
                                    <Button
                                        className="borderless green"
                                        id={`dataset-inspect-manage-${datasetId}`}
                                        title="Manage files"
                                        onClick={toggleManageFilesModal}
                                    >
                                        <i className="fas fa-pen" />
                                    </Button>
                                    {hasSeismicPlane(layerState) ? (
                                        <>
                                            <div className="grow" />
                                            <SeismicOptions layerState={layerState} />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            <ul className="list">
                                {hasSeismicPlane(layerState)
                                    ? SeismicList({
                                          dataset,
                                          sourcefiles,
                                          layerState,
                                          onEditSourceFile,
                                          canToggleSourceFile,
                                      })
                                    : DefaultList({
                                          dataset,
                                          sourcefiles,
                                          onEditSourceFile,
                                          canToggleSourceFile,
                                      })}
                                {sourcefiles?.length === 0 && 'No files yet'}
                            </ul>
                            {(uploading?.length ?? 0) !== 0 ? (
                                <>
                                    <div>Uploading ({uploading?.length ?? 0})...</div>
                                    <ul className="list">{uploading?.map((f) => <li key={f}>{f}</li>)}</ul>
                                </>
                            ) : null}
                            <Modal className="modal-lg" isOpen={manageFilesModal} toggle={toggleManageFilesModal}>
                                <SourceFileTable dataset={dataset} />
                            </Modal>
                            <Modal isOpen={uploadFilesModal} toggle={toggleUploadFilesModal}>
                                <UploadFilesModalForm dataset={dataset} onClose={onDatasetFileUploadClose} />
                            </Modal>
                        </>
                    )}
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default InspectDatasetMenu;
