import { SerializedState, STORY_TYPE, StoryMap } from 'types/serialization/View';
import Project from 'types/Project';
import InlineDropMenu from 'components/InlineDropMenu';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Dispatch, useAppDispatch, useAppSelector } from 'store';
import { Form, Formik } from 'formik';
import BaseInput from 'components/forms/BaseInput';
import Checkbox from 'components/Checkbox';
import {
    createStoryMap,
    deleteStoryView,
    fetchStoryMap,
    loadStoryViewByIndex,
    resetStoryOrder,
    updateLocalStoryOrder,
    updateStory,
    updateStoryOrder,
} from 'redux/actions';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMountEffect } from 'components/utils';
import ApiErrors from 'services/ApiErrors';
import * as giro3dSlice from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import { UUID } from 'types/common';
import { useEventBus } from 'EventBus';
import { PANE } from 'services/Constants';
import { createDateReadout, getUsername } from 'services/MentionFormatting';
import { getProjectUsers } from 'redux/selectors';
import { User } from 'types/User';
import { CreateViewForStory, EditViewForStory, SortableView } from './View';

const createUrl = (story, location) => {
    const slug = story.name
        .toString()
        .normalize('NFD') // break accented characters into components
        .replace(/[\u0300-\u036f]/g, '') // remove diacritics
        .toLowerCase()
        .replace(/\s+/g, '-') // spaces to dashes
        .replace(/&/g, '-and-') // ampersand to and
        .replace(/[^\w-]+/g, '') // remove non-words
        .replace(/--+/g, '-') // collapse multiple dashes
        .replace(/^-+/, '') // trim starting dash
        .replace(/-+$/, ''); // trim ending dash;

    // searchParams.toString will encode the / before the slug so we make our own searchParam string
    // We also remove all others search params as we assume this link is only for the story
    return `${window.location.origin + location.pathname}?story=${story.id}/${slug}`;
};

const copyUrl = (view, location, setCopyModal) => {
    navigator.clipboard.writeText(createUrl(view, location));
    setCopyModal(true);
    setTimeout(() => setCopyModal(false), 2000);
};

export const StoryItem = (props: {
    storyMap: StoryMap;
    project: Project;
    setDeleting;
    setCopyModal;
    highlight?: boolean;
}) => {
    const { storyMap, project, setDeleting, setCopyModal, highlight } = props;

    const dispatch = useAppDispatch();
    const location = useLocation();
    const eventBus = useEventBus();

    return (
        <li className={highlight ? 'highlight' : ''}>
            <div>
                <label htmlFor={`story-name-${storyMap.id}`}>
                    <button
                        id={`story-name-${storyMap.id}`}
                        type="button"
                        onDoubleClick={() =>
                            eventBus.dispatch('create-story-pane', {
                                paneType: PANE.STORY,
                                storyId: storyMap.id,
                                showExisting: true,
                            })
                        }
                    >
                        <i className="fas fa-rectangle-history" />
                        {storyMap.story_type === STORY_TYPE.PRIVATE ? <i className="fas fa-lock" /> : null}
                        <span>{storyMap.name}</span>
                    </button>
                </label>
                <Button
                    className="borderless green"
                    title="View Story"
                    onClick={() => dispatch(loadStoryViewByIndex(0, storyMap.id))}
                >
                    <i className="fas fa-play" />
                </Button>
                <InlineDropMenu>
                    <Button
                        className="borderless light-blue"
                        title="Copy Story Link"
                        onClick={() => copyUrl(storyMap, location, setCopyModal)}
                    >
                        <i className="fas fa-link" />
                    </Button>
                    {project.user_permissions.update_project ? (
                        <>
                            {/* <Button
                                className="borderless green"
                                title="Edit Story"
                                onClick={() => }
                            >
                                <i className="fas fa-pen" />
                            </Button> */}
                            <Button
                                className="borderless red"
                                title="Delete Story"
                                onClick={() => setDeleting(storyMap)}
                            >
                                <i className="fas fa-trash-can" />
                            </Button>
                        </>
                    ) : null}
                </InlineDropMenu>
            </div>
        </li>
    );
};

enum MODAL {
    PROMPT = 'prompt',
    PROCESSING = 'processing',
    CONFIRMATION = 'confirmation',
    ERROR = 'error',
}

const deleteViewModalContent = (
    story: StoryMap,
    deletingView: { view: SerializedState; state: MODAL; errorText: string },
    dispatch: Dispatch,
    setDeleting
) => {
    switch (deletingView.state) {
        case MODAL.PROMPT:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                        <span className="big-modal-text">Are you sure?</span>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => {
                                setDeleting({ ...deletingView, state: MODAL.PROCESSING });
                                dispatch(deleteStoryView(story, deletingView.view))
                                    .then(() => {
                                        setDeleting({ ...deletingView, state: MODAL.CONFIRMATION });
                                        setTimeout(() => setDeleting(undefined), 2000);
                                    })
                                    .catch((err) =>
                                        setDeleting({
                                            ...deletingView,
                                            state: MODAL.ERROR,
                                            errorText: ApiErrors.getErrorMessage(err),
                                        })
                                    );
                            }}
                        >
                            Yes, Delete this View
                        </button>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="pane-button large" onClick={() => setDeleting(undefined)}>
                            Cancel
                        </button>
                    </ModalFooter>
                </>
            );
        case MODAL.PROCESSING:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                        <span className="big-modal-text">Deleting View...</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.CONFIRMATION:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                        <span className="big-modal-text">View deleted</span>
                    </ModalBody>
                    <ModalFooter />
                </>
            );
        case MODAL.ERROR:
            return (
                <>
                    <ModalBody>
                        <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                        <span className="big-modal-text">An error occured</span>
                        <span className="small-modal-text">{deletingView.errorText}</span>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="pane-button large highlight"
                            onClick={() => setDeleting(undefined)}
                        >
                            OK
                        </button>
                    </ModalFooter>
                </>
            );
        default:
            return null;
    }
};

const EditStory = (props: { story: StoryMap; project: Project; setEditing }) => {
    const { story, project, setEditing } = props;

    const dispatch = useAppDispatch();

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: story?.name,
                    description: story?.description,
                    id: story?.id,
                    project_id: story?.project_id,
                    story_type: undefined,
                    setPrivate: story?.story_type === STORY_TYPE.PRIVATE,
                    views: story.views,
                    viewsLocal: story.viewsLocal,
                }}
                onSubmit={(values) => {
                    values.id = story.id;
                    values.project_id = project.id;
                    values.story_type = values.setPrivate ? STORY_TYPE.PRIVATE : STORY_TYPE.SHARED;
                    dispatch(updateStory(values)).then(() => setEditing(false));
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <BaseInput name="name" label="Story name" />
                        <hr />
                        <BaseInput name="description" label="Story Description" long />
                        <Checkbox
                            id="setPrivate"
                            label="Set Private Story"
                            checked={values.setPrivate}
                            onChange={(e) => setFieldValue('setPrivate', e.target.checked)}
                        />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setEditing(null)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export const ViewStory = (props: { storyId: UUID }) => {
    const { storyId } = props;

    const dispatch = useAppDispatch();
    const location = useLocation();

    const story = useAppSelector(giro3dSlice.getStory(storyId));
    const activeStory = useAppSelector(giro3dSlice.getActiveStory);
    const storyIndex = useAppSelector(giro3dSlice.getStoryIndex);
    const project = useAppSelector(datasetsSlice.currentProject);
    const users: User[] = useAppSelector(getProjectUsers);

    const order = story.viewsLocal !== undefined ? story.viewsLocal : [];

    useMountEffect(() => {
        if (!story.views) dispatch(fetchStoryMap(project.id, storyId));
    });

    const handleDragEndSort = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = order.indexOf(order.find((v) => v.id === active.id));
            const newIndex = order.indexOf(order.find((v) => v.id === over.id));
            const newArray = arrayMove(order, oldIndex, newIndex);
            dispatch(updateLocalStoryOrder(story, newArray));
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [creatingView, setCreatingView] = useState(false);
    const [editingView, setEditingView] = useState<SerializedState>();
    const [deletingView, setDeletingView] = useState<{ view: SerializedState; state: MODAL; errorText: string }>();
    const [editing, setEditing] = useState(false);
    const [copyModal, setCopyModal] = useState(false);

    if (creatingView) return <CreateViewForStory project={project} story={story} setCreating={setCreatingView} />;
    if (editingView) return <EditViewForStory view={editingView} story={story} setEditing={setEditingView} />;
    if (editing) return <EditStory story={story} project={project} setEditing={setEditing} />;

    const reordered = story.views && !story.views.every((view, index) => view.id === story.viewsLocal[index].id);

    return (
        <>
            <div className="tabContent">
                <div className="input-row">
                    <button
                        type="button"
                        className="pane-button"
                        onClick={() => dispatch(resetStoryOrder(story))}
                        disabled={!reordered}
                    >
                        Reset Order
                    </button>
                    <button
                        type="button"
                        className="pane-button highlight"
                        onClick={() => dispatch(updateStoryOrder(story))}
                        disabled={!reordered}
                    >
                        Save Order
                    </button>{' '}
                    <div className="grow" />
                    <button
                        type="button"
                        className="pane-button highlight"
                        title={`Edit ${story.name}`}
                        onClick={() => setEditing(true)}
                    >
                        <i className="fas fa-pen" />
                    </button>
                    <button
                        type="button"
                        className="pane-button highlight"
                        title="Add New View"
                        onClick={() => setCreatingView(true)}
                    >
                        <i className="fas fa-plus" />
                    </button>
                    <InlineDropMenu>
                        <Button
                            className="borderless light-blue"
                            title="Copy Story Link"
                            onClick={() => copyUrl(story, location, setCopyModal)}
                        >
                            <i className="fas fa-link" />
                        </Button>
                    </InlineDropMenu>
                </div>
                <div className="description">
                    <span className="title">{story.name}</span>
                    <span className="author-line">
                        {getUsername(story.created_by_id, users)} - {createDateReadout(story.created_at)} ago
                    </span>
                    {story.description}
                    <span className="author-line">
                        Updated by {getUsername(story.updated_by_id, users)} {createDateReadout(story.updated_at)} ago
                    </span>
                </div>
                {activeStory === story.id && storyIndex !== undefined ? (
                    <div className="description">
                        <span className="title">{story.viewsLocal[storyIndex].name}</span>
                        <span className="author-line">
                            {getUsername(story.viewsLocal[storyIndex].created_by_id, users)}{' '}
                            {createDateReadout(story.viewsLocal[storyIndex].created_at)} ago
                        </span>
                        {story.viewsLocal[storyIndex].description}
                        <span className="author-line">
                            Updated by {getUsername(story.viewsLocal[storyIndex].updated_by_id, users)}{' '}
                            {createDateReadout(story.viewsLocal[storyIndex].updated_at)} ago
                        </span>
                    </div>
                ) : null}

                {story.viewsLocal && story.viewsLocal.length !== 0 ? (
                    <ul className="list">
                        <DndContext sensors={sensors} onDragEnd={handleDragEndSort} autoScroll={false}>
                            <SortableContext id="views-sortable" items={order} strategy={verticalListSortingStrategy}>
                                {order?.map((view, index) => (
                                    <SortableView
                                        key={view.id}
                                        view={view}
                                        story={story}
                                        project={project}
                                        setDeleting={() =>
                                            setDeletingView({ view, state: MODAL.PROMPT, errorText: '' })
                                        }
                                        setEditing={setEditingView}
                                        setCopyModal={setCopyModal}
                                        highlight={activeStory === story.id && index === storyIndex}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </ul>
                ) : null}
            </div>
            <Modal isOpen={deletingView !== undefined} keyboard={false} centered className="modal-confirm">
                {deletingView !== undefined
                    ? deleteViewModalContent(story, deletingView, dispatch, setDeletingView)
                    : null}
            </Modal>
            <Modal isOpen={copyModal} keyboard={false} centered className="modal-confirm">
                <ModalHeader />
                <ModalBody>
                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                    <span className="big-modal-text">Link copied</span>
                </ModalBody>
                <ModalFooter />
            </Modal>
        </>
    );
};

export const CreateStory = (props: { project: Project; setCreating }) => {
    const { project, setCreating } = props;

    const dispatch = useAppDispatch();

    return (
        <div className="tabContent">
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    project_id: project.id,
                    story_type: undefined,
                    setPrivate: false,
                }}
                onSubmit={(values) => {
                    values.project_id = project.id;
                    values.story_type = values.setPrivate ? STORY_TYPE.PRIVATE : STORY_TYPE.SHARED;
                    dispatch(createStoryMap(values)).then(() => setCreating(undefined));
                }}
                validate={(values) => {
                    const errors: { name?: string } = {};
                    if (!values.name) errors.name = 'Required';
                    return errors;
                }}
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className="sideform">
                        <BaseInput name="name" label="Story Name" titled />
                        <hr />
                        <BaseInput name="description" label="Story Description" long />
                        <Checkbox
                            id="setPrivate"
                            label="Set Private Story"
                            checked={values.setPrivate}
                            onChange={(e) => setFieldValue('setPrivate', e.target.checked)}
                        />
                        <hr />
                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setCreating(null)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
