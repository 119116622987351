import { Modal, Popover, PopoverBody } from 'reactstrap';
import {
    generateElevationProfile,
    startAnnotationCreation,
    startMeasureDrawing,
    startProfileLineDrawing,
} from 'redux/actions';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as giro3dSlice from 'redux/giro3d';
import * as layersSlice from 'redux/layers';
import * as annotationsSlice from 'redux/annotations';
import * as drawToolSlice from 'redux/drawTool';
import { useEventBus } from 'EventBus';
import {
    ANNOTATION_CREATESTATE,
    DRAWN_TOOL,
    EDITSTATE,
    isElevationType,
    LAYER_DATA_TYPES,
    LAYER_STATES,
    LAYER_TYPES,
    PANE,
} from 'services/Constants';
import { shallowEqual } from 'react-redux';
import Dataset from 'types/Dataset';
import Notifications from 'components/notification/Notifications';
import Profile from 'components/authentication/Profile';
import Button from 'components/dropdown/Button';
import ToolbarButton from 'components/ToolbarButton';
import { hasAttributes } from 'types/LayerState';
import EditProject from './EditProject';
import HomeButton from '../HomeButton';
import StoryController from './viewMenu/StoryController';

const Toolbar = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();

    const tool = useAppSelector(drawToolSlice.getTool);

    const project = useAppSelector(datasetsSlice.currentProject);
    const datasets: Dataset[] = useAppSelector(datasetsSlice.getProjectDatasets, shallowEqual);
    const layers = useAppSelector(layersSlice.all);
    const vectorDatasets = datasets.filter((d) => d.type === LAYER_TYPES.VECTOR);
    const lineDatasets = datasets.filter(
        (d) => d.datatype === LAYER_DATA_TYPES.VECTOR && hasAttributes(layers.find((l) => l.datasetId === d.id))
    );

    const story = useAppSelector(giro3dSlice.getActiveStory);

    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);

    const [editProject, setEditProject] = useState(false);
    const [chartLinesList, setChartLinesList] = useState(false);
    const [attributesTableList, setAttributesTableList] = useState(false);

    const validDatasets = datasets.filter(
        (dataset) =>
            isElevationType(dataset.type) &&
            dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
            dataset.state === LAYER_STATES.LOADED &&
            layers.find((l) => l.datasetId === dataset.id)?.visible
    );

    let disabledAnnotationMessage;
    if (!project?.user_permissions.interact)
        disabledAnnotationMessage = 'You do not have the permissions to add annotations.';
    else if (editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE)
        disabledAnnotationMessage = 'There is already an annotation action ongoing.';

    return (
        <div className="toolbar">
            <HomeButton />
            <span>{project?.name}</span>
            <ToolbarButton
                title="Project Settings"
                iconName="fad fa-gear invert"
                className="settingsButton"
                id="settings"
                onClick={() => setEditProject(true)}
            />
            <Modal isOpen={editProject}>
                <EditProject project={project} endEdit={() => setEditProject(false)} />
            </Modal>
            <hr />
            <div className="button-set">
                <ToolbarButton
                    title="Elevation Profile"
                    iconName="fad fa-chart-area"
                    id="elevation-profile-tool"
                    onClick={() =>
                        dispatch(startProfileLineDrawing()).then((geometry) => {
                            if (geometry) {
                                const start = geometry.coordinates[0];
                                const end = geometry.coordinates[1];
                                dispatch(generateElevationProfile(start, end, validDatasets, 200));
                            }
                        })
                    }
                    highlight={tool === DRAWN_TOOL.ELEVATION_PROFILE}
                />
                <ToolbarButton
                    title="Measure Tool"
                    iconName="fad fa-ruler"
                    id="measure-tool"
                    onClick={() => dispatch(startMeasureDrawing())}
                    highlight={tool === DRAWN_TOOL.MEASURE}
                />
                <ToolbarButton
                    title="Create Annotation"
                    iconName="fad fa-sticky-note"
                    id="annotation-tool"
                    onClick={() => dispatch(startAnnotationCreation())}
                    disabledMessage={disabledAnnotationMessage}
                    highlight={tool === DRAWN_TOOL.ANNOTATION}
                />
            </div>
            <hr />
            <div className="button-set">
                <ToolbarButton
                    title="Chart Line"
                    iconName="fad fa-chart-line"
                    id="chart-lines"
                    onClick={() => setChartLinesList(!chartLinesList)}
                />
                <Popover
                    target="chart-lines"
                    placement="bottom"
                    toggle={() => setChartLinesList(false)}
                    trigger="legacy"
                    fade={false}
                    isOpen={chartLinesList}
                    hideArrow
                >
                    <PopoverBody>
                        {lineDatasets.length === 0 ? (
                            <span>There are no line datasets in this project</span>
                        ) : (
                            <ul>
                                {lineDatasets.map((dataset) => (
                                    <Button
                                        key={dataset.id}
                                        title={dataset.name}
                                        onClick={() => {
                                            eventBus.dispatch('create-dataset-config-pane', {
                                                paneType: PANE.CHART,
                                                data: { datasetId: dataset.id },
                                                showExisting: false,
                                            });
                                            setChartLinesList(false);
                                        }}
                                    />
                                ))}
                            </ul>
                        )}
                    </PopoverBody>
                </Popover>
                <ToolbarButton
                    title="Attributes"
                    iconName="fad fa-table invert"
                    id="attributes-table"
                    onClick={() => setAttributesTableList(!attributesTableList)}
                />
                <Popover
                    target="attributes-table"
                    placement="bottom"
                    toggle={() => setAttributesTableList(false)}
                    trigger="legacy"
                    fade={false}
                    isOpen={attributesTableList}
                    hideArrow
                >
                    <PopoverBody>
                        {vectorDatasets.length === 0 ? (
                            <span>There are no vector datasets in this project</span>
                        ) : (
                            <ul>
                                {vectorDatasets.map((dataset) => (
                                    <Button
                                        key={dataset.id}
                                        title={dataset.name}
                                        onClick={() => {
                                            eventBus.dispatch('create-dataset-pane', {
                                                paneType: PANE.ATTRIBUTE_TABLE,
                                                datasetId: dataset.id,
                                                showExisting: true,
                                            });
                                            setAttributesTableList(false);
                                        }}
                                    />
                                ))}
                            </ul>
                        )}
                    </PopoverBody>
                </Popover>
                <ToolbarButton
                    title="Share View"
                    iconName="fad fa-share-alt"
                    id="share-view"
                    onClick={() =>
                        eventBus.dispatch('create-pane', {
                            paneType: PANE.VIEWS,
                            showExisting: true,
                        })
                    }
                />
                {/* <Modal className="modal-md" isOpen={openExportUrlModal} toggle={() => setOpenExportUrlModal(false)}>
                    <ViewMenu closeMenu={() => setOpenExportUrlModal(false)} />
                </Modal> */}
            </div>
            <hr />
            <div className="spacer" />
            <hr />
            {story ? (
                <>
                    <StoryController />
                    <hr />
                </>
            ) : null}
            <div className="button-set">
                <ToolbarButton
                    title="Uploads"
                    iconName="fad fa-upload"
                    id={PANE.UPLOADS}
                    onClick={() => eventBus.dispatch('create-pane', { paneType: PANE.UPLOADS, showExisting: true })}
                />
                <Notifications />
                <Profile />
            </div>
        </div>
    );
};

export default Toolbar;
