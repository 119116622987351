import { nanoid } from '@reduxjs/toolkit';
import { BareSettingSlider } from 'components/giro3d/settingsMenu/inputs/SettingSlider';
import { ReactElement, useRef, useState } from 'react';
import { Popover } from 'reactstrap';

type ButtonProps = {
    onClick: () => void;
    disabled?: boolean;
    icon: string;
    name: string;
};

type ToggleProps = {
    onChange: (value) => void;
    checked: boolean;
    icon: string;
    name: string;
};

type SliderProps = {
    onChange: (value: number) => void;
    min: number;
    max: number;
    step: number;
    value: number;
    unit: string;
    icon: string;
};

type PopoverProps = {
    name: string;
    icon?: string;
    content: ReactElement;
    closeOnClick?: boolean;
    disabled?: boolean;
};

export type HeaderButtonProps = {
    id?: string;
    button?: ButtonProps;
    toggle?: ToggleProps;
    slider?: SliderProps;
    popover?: PopoverProps;
};

const Button = (props: ButtonProps) => {
    const id = nanoid();
    return (
        <div>
            <button type="button" id={id} onClick={props.onClick} disabled={props.disabled} title={props.name}>
                <i className={props.icon} />
            </button>
        </div>
    );
};

const Toggle = (props: ToggleProps) => {
    const id = nanoid();
    return (
        <div>
            <input type="checkbox" id={id} checked={props.checked} onChange={(e) => props.onChange(e.target.checked)} />
            <label htmlFor={id} title={props.name}>
                <i className={props.icon} />
            </label>
        </div>
    );
};

const Slider = (props: SliderProps) => {
    return (
        <div>
            <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.valueAsNumber)} />
            {props.unit}
            <BareSettingSlider
                min={props.min}
                max={props.max}
                value={props.value}
                step={props.step}
                onChange={props.onChange}
                title="Z-Scale"
            />
            <i className={props.icon} />
        </div>
    );
};

const PopoverButton = (props: PopoverProps) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    return (
        <div>
            <button type="button" ref={ref} onClick={() => setOpen(true)} title={props.name} disabled={props.disabled}>
                <i className={props.icon ?? 'fas fa-angle-down'} />
            </button>
            <Popover
                hideArrow
                isOpen={isOpen}
                target={ref}
                toggle={() => setOpen(false)}
                trigger="legacy"
                fade={false}
                placement="bottom"
                onClick={props.closeOnClick ? () => setOpen(false) : undefined}
            >
                {props.content}
            </Popover>
        </div>
    );
};

const HeaderButton = (props: HeaderButtonProps) => {
    return (
        <div className="tabHeaderInput" id={props.id}>
            {props.button ? <Button {...props.button} /> : null}
            {props.toggle ? <Toggle {...props.toggle} /> : null}
            {props.slider ? <Slider {...props.slider} /> : null}
            {props.popover ? <PopoverButton {...props.popover} /> : null}
        </div>
    );
};

export default HeaderButton;
