import { createInterpolatorWithFallback } from 'commons-math-interpolation';
import { MathUtils } from 'three';

export type CurveKnot = { x: number; y: number };

export type Curve = {
    knots: Array<CurveKnot>;
};

export function getDefaultOpacityCurveKnots(): Array<CurveKnot> {
    return [
        { x: 0, y: 1 },
        { x: 1, y: 1 },
    ];
}

/**
 * Evaluate the curve and return an array of values.
 */
export function evaluateCurve(
    curve: Curve,
    options: { samples: number; start?: number; end?: number; minY?: number; maxY?: number }
): number[] {
    const result = new Array<number>(options.samples);
    if (curve) {
        const min = options.minY ?? -Infinity;
        const max = options.maxY ?? +Infinity;

        const n = curve.knots.length;
        const xVals = new Float64Array(n);
        const yVals = new Float64Array(n);
        for (let i = 0; i < n; i++) {
            xVals[i] = curve.knots[i].x;
            yVals[i] = curve.knots[i].y;
        }
        // Currently only linear interpolations are supported in SCOPE.
        const fn = createInterpolatorWithFallback('linear', xVals, yVals);

        const start = options.start ?? 0;
        const end = options.end ?? 1;

        for (let i = 0; i < result.length; i++) {
            const normalized = i / result.length;
            const t = MathUtils.mapLinear(normalized, 0, 1, start, end);

            const raw = fn(t);

            result[i] = MathUtils.clamp(raw, min, max);
        }
    } else {
        result.fill(1);
    }

    return result;
}
