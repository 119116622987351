// React
import { useDispatch, useSelector } from 'react-redux';

import * as giro3dSlice from 'redux/giro3d';
import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';

// Components
import HeaderButton from 'components/flexLayout/HeaderButton';

function ZScaleSetting() {
    const dispatch = useDispatch();

    const scale = useSelector(giro3dSlice.getZScale);

    return (
        <HeaderButton
            key="zScale"
            slider={{
                unit: '%',
                min: 50,
                max: 2000,
                step: 10,
                value: Math.round(scale * 100),
                onChange: (v) => dispatch(giro3dSlice.setZScale(v / 100 || DEFAULT_GIRO3D_SETTINGS.Z_SCALE)),
                icon: 'fas fa-arrows-up-down',
            }}
        />
    );
}

export default ZScaleSetting;
