import { Color, MathUtils } from 'three';
import Giro3DColorMap, { ColorMapMode } from '@giro3d/giro3d/core/ColorMap';
import { WellKnownAttributeNames } from 'types/LayerState';

export default class LUT {
    readonly colors: Color[];

    readonly minV: number;

    readonly maxV: number;

    constructor(colors: Color[], min: number, max: number) {
        this.colors = colors;
        this.minV = min;
        this.maxV = max;
    }

    sample(absoluteValue: number): Color {
        let alpha = MathUtils.clamp(absoluteValue, this.minV, this.maxV);

        alpha = (alpha - this.minV) / (this.maxV - this.minV);

        const colorPosition = Math.round(alpha * (this.colors.length - 1));

        return this.colors[colorPosition];
    }
}

export function toFloat32Array(colors: Color[], target: Float32Array): Float32Array {
    const result = target || new Float32Array(colors.length * 3);

    for (let i = 0; i < colors.length; i++) {
        const c = colors[i];
        result[i * 3 + 0] = c.r;
        result[i * 3 + 1] = c.g;
        result[i * 3 + 2] = c.b;
    }

    return result;
}

export function updateGiro3DColorMap(
    src: { min: number; max: number; attributeName: string; lut: Color[] },
    dst: Giro3DColorMap
) {
    dst.min = src.min;
    dst.max = src.max;

    switch (src.attributeName) {
        case WellKnownAttributeNames.Slope:
        case WellKnownAttributeNames.Gradient:
            dst.mode = ColorMapMode.Slope;
            break;
        case WellKnownAttributeNames.Aspect:
            dst.mode = ColorMapMode.Aspect;
            break;
        default:
            dst.mode = ColorMapMode.Elevation;
            break;
    }

    dst.colors = src.lut;
}
