import { useAppDispatch, useAppSelector } from 'store';
import * as giro3dSlice from 'redux/giro3d';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { loadStoryViewByIndex } from 'redux/actions';

const StoryController = () => {
    const dispatch = useAppDispatch();
    const storyId = useAppSelector(giro3dSlice.getActiveStory);
    const story = useAppSelector(giro3dSlice.getStory(storyId));
    const storyIndex = useAppSelector(giro3dSlice.getStoryIndex);

    if (!story || story.viewsLocal === undefined) return null;
    const view = storyIndex !== undefined ? story.viewsLocal[storyIndex] : undefined;

    return (
        <div className="storyController">
            <Button className="borderless" onClick={() => dispatch(giro3dSlice.setActiveStory(undefined))}>
                <i className="fas fa-xmark" />
            </Button>
            <span id="story-name">{story.name}</span>
            {story.description ? (
                <UncontrolledTooltip target="story-name" placement="bottom" hideArrow>
                    {story.description}
                </UncontrolledTooltip>
            ) : null}
            |<span id="story-view-name">{view ? view.name : 'No View'}</span>
            {view?.description ? (
                <UncontrolledTooltip target="story-view-name" placement="bottom" hideArrow>
                    {view.description}
                </UncontrolledTooltip>
            ) : null}
            <Button
                className="borderless navigation"
                onClick={() => dispatch(loadStoryViewByIndex(storyIndex !== undefined ? storyIndex - 1 : 0, storyId))}
                disabled={storyIndex === 0 || storyIndex === undefined}
            >
                <i className="fas fa-chevron-left" />
            </Button>
            <span>{view ? `${storyIndex + 1}/${story.views.length}` : `?/${story.views.length}`}</span>
            <Button
                className="borderless navigation"
                onClick={() => dispatch(loadStoryViewByIndex(storyIndex !== undefined ? storyIndex + 1 : 0, storyId))}
                disabled={storyIndex === story.views.length - 1}
            >
                <i className="fas fa-chevron-right" />
            </Button>
        </div>
    );
};

export default StoryController;
